import React, { Component } from "react";
import { connect } from "react-redux";
import "./Category.css";
import { addToCart } from "../../../actions/products";
import { Index } from "../Index/Index";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      id: props.type,
    };
  }

  componentDidMount() {
    let id = this.state.id;
    //console.log('component did mount',this.state.products, this.state.byId)

    if (!id) {
      if (this.props.match.params.id) {
        id = this.props.match.params.id;
      } else {
        id = "";
      }
    }
  }

  hanldeAddToCart(id) {
    this.props.addToCart(id);
  }

  render() {
    let contents = this.props.products.length
      ? this.props.products.map((item) => {
          return Index.renderCard(item);
        })
      : "";
    const productsCount = this.props.products.length;
    const title =
      productsCount > 0 ? (
        <h5>
          Có {this.props.products.length} sản phẩm{" "}
          {this.props.match.params.title}{" "}
        </h5>
      ) : (
        <h4>Không tìm thấy sản phẩm "{this.props.match.params.title}" nào</h4>
      );
    return (
      <div className="mt-3 pt-3 pb-3 pl-3 bg-white">
        <div className="container dark-grey-text">
          {title}
          <div className="card-columns">{contents}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let allProducts = [];
  allProducts = Object.values(state.products.allProducts); // || {};
  //console.log('mapStateToProps',allProducts);
  let products = [];
  if (allProducts && allProducts.length) {
    allProducts.map(
      (item) =>
        item.typeID === parseInt(ownProps.match.params.id) && products.push(item)
    );
  }
  return { products };
};

export default connect(mapStateToProps, { addToCart })(Category);
