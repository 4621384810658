import { combineReducers } from 'redux'
import authReducer from './auth-reducer'
import cart, * as fromCart from './cart'
import products, * as fromProducts from './products'

export default combineReducers({
  cart,
  products,
  authReducer
})

const getAddedIds = state => fromCart.getAddedIds(state.cart)
const getQuantity = (state, id) => fromCart.getQuantity(state.cart, id)
const getProduct = (state, id) => fromProducts.getProduct(state.products, id)
const getCheckoutStatus = state =>fromCart.checkOutOrder(state.cart)

export const checkoutOrder = (state) => {
  //console.log('reducer checkout order',state);
  return getCheckoutStatus(state)
}

export const getTotal = state =>
  getAddedIds(state)
    .reduce((total, id) =>
      total + getProduct(state, id).price * getQuantity(state, id),
      0
    )
    .toFixed(0)

export const getCartProducts = (state) =>{
  //console.log('reducer getcartproducts',state);
  return getAddedIds(state).map(id => ({
    ...getProduct(state, id),
    quantity: getQuantity(state, id)
  }))
}
