import React, { Component } from "react";
import { Spinner, Form, Button } from "react-bootstrap";
import { GetTransactionById, CreateTransaction, EditTransaction } from "../../../api/TransactionAPI";
import CustomersAPI from "../../../api/CustomerAPI";
import Cleave from "cleave.js/react";
import Select, { createFilter } from "react-select";
import {FormatDate} from "../../../helpers/date-time";

export class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Tạo",
      loading: false,
      customerOptions: [],
      customerSelectedOption: null,
      id: 0,
      customerId: 0,
      customerName:"",
      amount: "",
      transactionTime: new Date().toISOString().substr(0, 10),
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);    
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }
  componentDidMount() {
    CustomersAPI.getAll().then((list) => {
      if (list.length > 0) {
        let options = [];

        list.forEach((element) => {
          let option = {
            value: element.id,
            label: element.name,
          };

          options.push(option);
        });

        this.setState({ customerOptions: options });
      }
    });

    const id = this.props.match.params["id"];

    // This will set state for Edit
    if (id) {
      this.setState({ loading: true, title: "Sửa" });
      GetTransactionById(id).then((data) => {
        if (data) {

          const option = {
            value: data.customerId,
            label: data.customerName
          }

          this.setState({
            id: data.id,
            customerId: data.customerId,
            customerName:data.customerName,
            amount: data.amount,
            transactionTime: FormatDate(data.transactionTime),
            loading: false,
            customerSelectedOption: option
          },()=>{console.log(this.state)});
        } else {
          //error
        }
      });
    } else {
    }
  }

  handleBack() {
    this.props.history.push("/boss/transactions/");
  }

  handleDateChange(event){
    const { name, value, type, checked } = event.target;

    this.setState({
      transactionTime: value,
    });
  }

  handleCustomerChange(name,id) {
    const selectedOption = {
        value: id,
        label: name,
      };

      this.setState({customerId:id, customerName:name, customerSelectedOption:selectedOption});
     
  }
  handleAmountChange(value) {
    this.setState({amount:value});
  }

  handleSubmit(e) {
    e.preventDefault();

    if(!this.state.amount){
        alert('Thiếu số tiền');
        return;
    }
    if(!this.state.customerId){
        alert('Chưa chọn khách hàng');
        return;
    }

    let item = {
        customerId: this.state.customerId,
        amount: parseFloat(this.state.amount),
        transactionTime: this.state.transactionTime
    }

    if(this.state.id > 0){
        item.id = this.state.id;

        EditTransaction(item).then(res =>{
            if(res){
                if(res.success){
                    alert("Sửa thành công")
                }
            }
        });

    }else{
        CreateTransaction(item).then(res =>{
            if(res){
                if(res.success){
                    alert("Tạo thành công")
                }
            }
        });
    }

  }

  render() {
    const filterConfig = {
      ignoreCase: true,
      ignoreAccesnt: true,
      trim: true,
      matchForm: "any",
    };

    const content = this.state.loading ? (
      <h4>
        <Spinner animation="border" /> Loading... ⌛️
      </h4>
    ) : (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group controlId="transactiontime">
            <Form.Label>Ngày thanh toán</Form.Label>
            <input
              type="date"
              onChange={this.handleDateChange}
              value={this.state.transactionTime}
              className="form-control"
            ></input>
          </Form.Group>
          <Form.Group controlId="customer">
            <Form.Label>Khách hàng</Form.Label>
            <Select
              value={this.state.customerSelectedOption}
              options={this.state.customerOptions}
              onChange={(opt) =>
                this.handleCustomerChange(opt.label, opt.value)
              }             
              filterOption={createFilter(filterConfig)}
            ></Select>
          </Form.Group>
          <Form.Group controlId="amount">
            <Form.Label>Số tiền</Form.Label>
            <Cleave
              name="txtAmount"
              className="input-numereal form-control text-right"
              options={{
                numeral: true,
                numeralThousandsGroupStyle: "thousand",
              }}
              onChange={(e) => this.handleAmountChange(e.target.rawValue)}
              value={this.state.amount}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Lưu
          </Button>
          <Button className="ml-3" variant="light" type="button" onClick={this.handleBack}>
            Thoát
          </Button>
        </Form>
      </div>
    );

    return (
      <div className="mt-3 pt-3 pb-3 pl-3 pr-3 bg-white">
        <h2>💵 {this.state.title} phiếu thu</h2>
        <hr />
        {content}
      </div>
    );
  }
}
