import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { Carousels } from "./Carousel";
import "./product-card.css";

export class ProductBrand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      loading: true,
      brand: props.brand,
      records: props.records,
    };
  }

  componentDidMount() {
    //console.log(this.state);
    let brand = this.state.brand;
    let records = this.state.records;

    if (!brand) {
      if (this.props.match.params.brand) brand = this.props.match.params.brand;
      else brand = "";
    }

    if (!records) {
      if (this.props.match.params.records)
        records = this.props.match.params.records;
    }

    let url = `api/Products/?brand=${brand}&records=${records}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ products: data, loading: false });
      });
  }

  handleClick(id) {
    this.props.history.push("/details/" + id);
  }

  render() {
    let tempPath = "/img/product/notfound.webp";
    let contents = this.state.loading ? (
      <p>
        <em>Loading...</em>
      </p>
    ) : this.state.products.length > 0 ? (
      this.state.products.map((item) => {
        return (
          <Card key={item.id} body={true} border={"light"}>
            <Link
              to={"/details/" + item.id}
              style={{ textDecoration: "none", color: "black" }}
            >
              <Card.Img
                className="card-img-top img-fluid"
                variant="top"
                alt={item.imageAlt}
                src={
                  item.thumbnailPath
                    ? "https://tiemvattu.com/api/Files/GetFile?path=" +
                      item.thumbnailPath
                    : tempPath
                }
                alt={item.name}
              />

              <Card.Body>
                {/* <Card.Title>
                    
                  </Card.Title> */}

                <div className="card-text">
                  {/* <Link
                      style={{ textDecoration: "none", color: "black" }}
                      to={"/details/" + item.id}
                    > */}
                  {item.name}
                  {/* <br/>
                      {item.price.toLocaleString(navigator.language, {
                      minimumFractionDigits: 0
                    })}
                    đ */}
                  {/* </Link> */}
                </div>
              </Card.Body>
            </Link>
          </Card>
        );
      })
    ) : (
      "No products found"
    );

    return (
      <Container>
        <div className="mt-3 mb-3 pt-3 pl-3 bg-white">
          <div className="card-columns">{contents}</div>
        </div>
      </Container>
    );
  }
}
