import Config from "../Config";
import { getWithExpiry } from "../helpers/LocalStorage";

const OrdersAPI = {
  buyProducts,
  create,
  edit,
  getById,
  createOnlineOrder,
  editOnlineOrder,
  getOnlineOrders,
  getOnlineOrderById,
  deleteOrderById,
};
async function getOnlineOrderById(id) {
  const url = `${Config.apiUrl}Orders/OnlineOrders/${id}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    return await response.json();
  } catch (err) {
    console.log(err);
  }
}
async function getOnlineOrders() {
  const url = `${Config.apiUrl}Orders/?online=true`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    return await response.json();
  } catch (err) {
    console.log(err);
  }
}
async function createOnlineOrder(order) {
  const url = `${Config.apiUrl}Orders/OnlineOrders`;

  //console.log('api create online order',order);
  const payload = JSON.stringify(order);
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: payload,
    });
    //console.log('api', response);
    return await response.json();
  } catch (err) {
    console.log(err);
  }
}

async function editOnlineOrder(order, id) {
  const url = `${Config.apiUrl}Orders/OnlineOrders/${id}`;

  const payload = JSON.stringify(order);
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: payload,
    });

    return await response.json();
  } catch (err) {
    console.log(err);
  }
}

async function buyProducts(order) {
  const url = Config.apiUrl + "Orders/CustomerOrder";

  const data = JSON.stringify(order);

  const resp = await fetch(url, {
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await resp.json();
}

async function create(item) {
  const url = Config.apiUrl + "Orders/CustomerOrder";
  //const token = localStorage.getItem("user");
  const payload = JSON.stringify(item);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      //Authorization: "Bearer " + token
    },
    body: payload,
  });

  return await response.json();
}

async function edit(item, id) {
  const url = Config.apiUrl + "Orders/" + id;

  const payload = JSON.stringify(item);
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: payload,
    });

    return await response.json();
  } catch (err) {
    console.log(err);
  }
}

async function getById(id) {
  try {
    const url = Config.apiUrl + "Orders/" + id;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    return await response.json();
  } catch (err) {
    console.log(err);
  }
}

async function deleteOrderById(id) {
  const url = `${Config.apiUrl}Orders/${id}`;

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    return await response.json();
  } catch (err) {
    console.log(err);
  }
}

export default OrdersAPI;
