import React from 'react';
import { Route } from 'react-router-dom';
import { Layout } from './Layout/Layout';

export const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );