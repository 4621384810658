export function GetMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export function FormatDate(date,option) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  if(option){
    if(option.toLowerCase()==="dmy"){
      return [day,month,year].join("-");
    }
  }
  return [year, month, day].join("-");
}
export function FormatDateTime(date) {
  var d = new Date(date),
    hour = "" + (d.getHours()),
    minute = "" + (d.getMinutes()),
    second = "" + (d.getSeconds()),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  const time = [hour, minute, second].join(":");
  const dateformat = [day,month,year].join("/");

  return dateformat + " " + time;
}

export function FirstDateOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function LastDateOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function FirstDateOfLastMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() - 1, 1);
}

export function LastDateOfLastMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 0);
}

export function DateRangeQuery(period) {
  const today = new Date();
  var param = "";
  var monday = GetMonday(new Date());
  switch (period) {
    case "thisweek":
      var weekend = new Date().setDate(monday.getDate() + 6);
      param += "&from=" + FormatDate(monday) + "&to=" + FormatDate(weekend);
      break;
    case "lastweek":
      var lastmonday = new Date().setDate(monday.getDate() - 7);
      var lastweekend = new Date().setDate(monday.getDate() - 1);
      param +=
        "&from=" + FormatDate(lastmonday) + "&to=" + FormatDate(lastweekend);
      break;
    case "thismonth":
      const firstDate = FirstDateOfMonth(today);
      const lastDate = LastDateOfMonth(today);
      param += "&from=" + FormatDate(firstDate) + "&to=" + FormatDate(lastDate);
      break;
    case "lastmonth":
      const firstDateLastMonth = FirstDateOfLastMonth(today);
      const lastDateLastMonth = LastDateOfLastMonth(today);
      param +=
        "&from=" +
        FormatDate(firstDateLastMonth) +
        "&to=" +
        FormatDate(lastDateLastMonth);
      break;
    default:
      break;
  }
  return param;
}

export function TimeGreeting() {
  const today = new Date();
  const hour = today.getHours();
  //console.log(hour);
  if (hour >= 4 && hour < 12) {
    return "sáng 🌞";
  } else if (hour >= 12 && hour < 15) {
    return "trưa ☀️";
  } else if (hour >= 15 && hour < 18) {
    return "chiều ⛅️";
  } else {
    return "tối 🌚";
  }
}

export function isToday(dateInput) {
  //console.log(dateInput);

  if (!dateInput) {
    return false;
  }

  const date = new Date(dateInput);

  const today = new Date();

  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getYear() == today.getYear()
  );
}

export function isYesterday(dateInput) {
  //console.log(dateInput);

  if (!dateInput) {
    return false;
  }

  const date = new Date(dateInput);

  const yesterday = new Date();

  yesterday.setDate(yesterday.getDate() - 1);
  //console.log('yesterday', yesterday);

  return (
    date.getDate() == yesterday.getDate() &&
    date.getMonth() == yesterday.getMonth() &&
    date.getYear() == yesterday.getYear()
  );
}

export function MonthList(){
  return [
    {
      name:"Tháng 1",
      value:1
    },{
      name:"Tháng 2",
      value:2
    },{
      name:"Tháng 3",
      value:3
    },{
      name:"Tháng 4",
      value:4
    },{
      name:"Tháng 5",
      value:5
    },{
      name:"Tháng 6",
      value:6
    },{
      name:"Tháng 7",
      value:7
    },{
      name:"Tháng 8",
      value:8
    },{
      name:"Tháng 9",
      value:9
    },{
      name:"Tháng 10",
      value:10
    },{
      name:"Tháng 11",
      value:11
    },{
      name:"Tháng 12",
      value:12
    },
  ]
}