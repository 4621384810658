import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Form, FormControl, Button } from "react-bootstrap";
import Cleave from "cleave.js/react";
import Select from "react-select";
import { Link } from "react-router-dom";
import SuppliersAPI from "../../../api/SuppliersAPI";
import OptionListAPI from "../../../api/OptionListAPI";
import BrandAPI from "../../../api/BrandAPI";
import { injectGlobal } from "emotion";

const SupplierForm = (props) => {
  const initData = {
    id: 0,
    name: "",
    phone: "",
    address: "",
    email: "",
    types: [],
    brands: [],
    products:[]
  };
  const { products } = useSelector(
    (state) => ({
      products: Object.values(state.products.allProducts),
    }),
    shallowEqual
  );
  const [productsOptions, setProductsOptions] = useState([]);
  const [productTypesOptions, setProductTypesOptions] = useState([]);
  const [
    selectedProductTypesOptions,
    setSelectedProductTypesOptions,
  ] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [selectedBrandsOptions, setSelectedBrandsOptions] = useState([]);
  const [selectedProductsOptions, setSelectedProductsOptions] = useState([]);
  const [data, setData] = useState(initData || {});

  useEffect(() => {
    OptionListAPI.getOptionListByKey("product-type").then((res) => {
      if (res && res.length) {
        let options = [];
        res.forEach((element) => {
          const op = {
            value: element.id,
            label: element.name,
          };
          options.push(op);
        });
        //console.log(options);
        setProductTypesOptions(options);
      }
    });
    BrandAPI.getAll().then((res) => {
      if (res && res.length) {
        let options = [];
        res.forEach((element) => {
          const op = {
            value: element.id,
            label: element.name,
          };
          options.push(op);
        });
        //console.log(options);
        setBrandsOptions(options);
      }
    });
    //console.log(props.id);
    if (props.id > 0) {
      SuppliersAPI.getById(props.id).then((res) => {
        if (res) {
          setData(res);
        } 
      });
    }
    console.log("use effect");
  }, []);
  useEffect(()=>{
    if(productTypesOptions.length > 0 && data.types.length > 0){
    console.log('use effect types options');
    let selectedTypesOptions = [];
          data.types.forEach((element) => {
            const op = productTypesOptions.find((x) => x.value === element);
            //console.log(productTypesOptions, op)
            if (op) {
              selectedTypesOptions.push(op);
            }
          });
          setSelectedProductTypesOptions(selectedTypesOptions);
    }
    
  },[productTypesOptions, data.types]);

useEffect(()=>{
  if(products.length > 0 && productsOptions.length == 0){

    let options = [];
  products.forEach((element) => {
          const op = {
            value: element.id,
            label: element.name,
          };
          options.push(op);
        });
        setProductsOptions(options);
    console.log('products options');
  }
},[products, productsOptions])

  useEffect(()=>{
    if(brandsOptions.length > 0 && data.brands.length > 0){
    console.log('use effect brandsOptions');
    let selectedBrandsOptions = [];
          data.brands.forEach((element) => {
            const op = brandsOptions.find((x) => x.value === element);
            //console.log(brandsOptions, op);
            if (op) {
              selectedBrandsOptions.push(op);
            }
          });
          setSelectedBrandsOptions(selectedBrandsOptions);
    }
    
  },[brandsOptions, data.brands]);

  const handleChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    //console.log(name, value);
    if (name === "phone") {
      value = e.target.rawValue;
    }
    e.persist();
    setData({ ...data, [name]: value });
  };

  const handleTypesChange = (selectedOptions) => {
    //console.log(selectedOptions);
    let options = [];
    if (selectedOptions) {
      for (let i = 0; i < selectedOptions.length; i++) {
        if (selectedOptions[i].value) {
          options.push(parseInt(selectedOptions[i].value));
        }
      }
    }
    setData({ ...data, types: options });
  };

  const handleBrandsChange = (selectedOptions) => {
    //console.log(selectedOptions);
    let options = [];
    if (selectedOptions) {
      for (let i = 0; i < selectedOptions.length; i++) {
        if (selectedOptions[i].value) {
          options.push(parseInt(selectedOptions[i].value));
        }
      }
    }
    setData({ ...data, brands: options });
  };

  const handleProductsChange = (selectedOptions) =>{
    console.log('selected products change');
    let options = [];
    if (selectedOptions) {
      for (let i = 0; i < selectedOptions.length; i++) {
        if (selectedOptions[i].value) {
          options.push(parseInt(selectedOptions[i].value));
        }
      }
    }
    setData({ ...data, products: options });
    setSelectedProductsOptions(selectedOptions);
  };

  const handleSave = (e) => {
    e.preventDefault();
    console.log("save", data);

    if (!data.name && !data.phone) {
      alert("please enter name or phone");
      return;
    }

    if (data.id > 0) {
      //update
      SuppliersAPI.update(data).then((res) => {
        if(res){
          alert('Sửa thành công');
        }else{
          alert('Error!');
        }
      });
    } else {
      //create
      SuppliersAPI.create(data).then((res) => {
        if(res){
          alert('Tạo thành công');
        }else{
          alert('Error!');
        }
      });
    }
  };

  return (
    <div className="mt-3 pt-3 pb-3 pl-3 pr-3 bg-white">
      <h4>Nhà cung cấp</h4>
      <hr />
      <Form onSubmit={handleSave}>
        <Form.Group controlId="name">
          <Form.Label>Nhà cung cấp</Form.Label>
          <FormControl
            name="name"
            value={data.name}
            onChange={handleChange}
          ></FormControl>
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.Label>Điện thoại</Form.Label>
          <Cleave
            name="phone"
            className="input-element form-control text-right"
            options={{
              delimiter: "-",
              blocks: [3, 4, 4, 4],
              delimiterLazyShow: true,
            }}
            onChange={handleChange}
            value={data.phone}
          />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            name="email"
            value={data.email}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="address">
          <Form.Label>Địa chỉ</Form.Label>
          <Form.Control
            name="address"
            value={data.address}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="productBrands">
          <Form.Label>Thương hiệu</Form.Label>
          <Select
            isMulti
            name="brands"
            closeMenuOnSelect={false}
            options={brandsOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleBrandsChange}
            value={selectedBrandsOptions}
          />
        </Form.Group>
        <Form.Group controlId="productTypes">
          <Form.Label>Loại sản phẩm</Form.Label>
          <Select
            isMulti
            name="types"
            closeMenuOnSelect={false}
            options={productTypesOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleTypesChange}
            value={selectedProductTypesOptions}
          />
        </Form.Group>
        <Form.Group controlId="productTypes">
          <Form.Label>Sản phẩm</Form.Label>
          <Select
            isMulti
            name="products"
            closeMenuOnSelect={false}
            options={productsOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleProductsChange}
            value={selectedProductsOptions}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Lưu
        </Button>
        <Link to="/boss/suppliers/" className="btn btn-default">
          Thoát
      </Link>
      </Form>
    </div>
  );
};
export default SupplierForm;
