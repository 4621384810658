import React, { Component } from "react";
//import Product from './Product';
import { connect } from "react-redux";
//import PropTypes from 'prop-types';
import { getCartProducts, getTotal, checkoutOrder } from "../../../reducers";
import { checkout } from "../../../actions/products";
//import './cart.css';
import { Form, Row, Col, Button } from "react-bootstrap";

class Checkout extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      data: props.products,
      checkoutStatus: props.finishCheckout,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  componentDidMount() {
    //console.log("didmount", this.state);
  }

  shouldComponentRender() {
    //const {finishCheckout} = this.props;

    //console.log('shouldComponentRender', this.props, this.props.finishCheckout)

    if (this.props.finishCheckout) return true;
    // more tests
    return false;
  }

  handleSubmit(e) {
    e.preventDefault();
    const customerName = this.getCustomerName.value;
    const customerPhone = this.getCustomerPhone.value;
    const customerAddress = this.getCustomerAddress.value;
    const customerEmail = this.getCustomerEmail.value;
    const note = this.getNote.value;
    const products = this.state.data;

    if (!customerName) {
      alert("Vui lòng nhập tên người mua hàng");
      return;
    }
    if (!customerPhone) {
      alert("Vui lòng nhập số điện thoại người mua");
      return;
    }
    if (!customerAddress) {
      alert("Vui lòng nhập địa chỉ giao hàng");
      return;
    }
    if (!products && products.length < 1) {
      alert("Chưa có sản phẩm nào trong giỏ hàng");
      return;
    }

    let orderItems = [];

    for (let i = 0; i < products.length; i++) {
      const item = {
        productId: products[i].id,
        quantity: products[i].quantity,
        price: products[i].price,
        id: 0,
        orderId: 0,
      };
      orderItems.push(item);
    }

    const data = {
      orderDate: new Date(),
      customerName,
      customerPhone,
      customerEmail,
      customerAddress,
      note,
      orderItems,
    };
    console.log("checkout submit", data);
    this.props.checkout(data);
  }

  render() {
    //const {finishCheckout} = this.props;

    if (this.shouldComponentRender()) {
      return (
        <div className="container">
          <div className="mt-3 pb-3 pt-3 pl-3 pr-3 bg-white">
            <h2>Đặt hàng thành công.</h2>
            <p>
              {" "}
              Vui lòng liên hệ 07 6861 6869 nếu khách hàng có thắc mắc thêm
              thông tin gì về đơn hàng của mình.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="container">
        <div className="mt-3 pb-3 pt-3 pl-3 pr-3 bg-white">
          <h3>Xác nhận đơn đặt hàng</h3>
          <hr></hr>
          {/*<h3>Đặt hàng</h3>
                <p>{this.props.products[0]}</p>
                <h3>Giỏ hàng của bạn</h3>
                <div className="cart"><ul className="collection">{nodes}</ul></div>
                <h4>Tổng cộng: {total.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}đ</h4>*/}
          <Form onSubmit={this.handleSubmit}>
            <Form.Group as={Row} controlId="formHorizontalCustomerName">
              <Form.Label column sm={2}>
                Khách hàng
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder="Tên khách hàng"
                  required
                  ref={(input) => (this.getCustomerName = input)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formHorizontalPhone">
              <Form.Label column sm={2}>
                Số điện thoại
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="mobile"
                  placeholder="Nhập số điện thoại"
                  required
                  ref={(input) => (this.getCustomerPhone = input)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={2}>
                Email
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="email"
                  placeholder="Nhập email"
                  required
                  ref={(input) => (this.getCustomerEmail = input)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formHorizontalAddress">
              <Form.Label column sm={2}>
                Địa chỉ giao hàng
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder="Nhập địa chỉ"
                  required
                  ref={(input) => (this.getCustomerAddress = input)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formHorizontalNote">
              <Form.Label column sm={2}>
                Ghi chú
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder="Nhập ghi chú"
                  ref={(input) => (this.getNote = input)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col sm={{ span: 10, offset: 2 }}>
                <Button variant="success" onClick={this.handleSubmit}>
                  Đặt hàng
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: getCartProducts(state),
    total: getTotal(state).toLocaleString(navigator.language, {
      minimumFractionDigits: 0,
    }),
    finishCheckout: checkoutOrder(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkout: (products) => {
      dispatch(checkout(products));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);

//export default connect()(Checkout);
