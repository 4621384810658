import React, {} from "react";
import { Redirect } from 'react-router-dom';
import {
  login
} from "../../actions/users";
import { connect } from "react-redux";

class Login extends React.Component {
  constructor(props) {
    super(props);

    // remove user from local storage to log user out
    //userService.logout();

    this.state = {
      username: "",
      password: "",
      submitted: false,
      loading: false,
      error: "",
    };
    

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  //static contextType = AuthContext;

  //console.log(contextType);

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;

    // stop here if form is invalid
    if (!(username && password)) {
      return;
    }

    this.setState({ loading: true });

    const { from } = this.props.location.state || {
            from: { pathname: "/boss/" },
          };

    this.props.login(username, password, from);

    // usersAPI.signIn(body).then(response => {
    //   if (response && response.success) {
    //     this.context.dispatch({
    //       type: 'LOG_IN',
    //       token: response.token
    //     });
    //     this.setState({ loading: false });

    //     const { from } = this.props.location.state || {
    //       from: { pathname: "/boss/" },
    //     };
    //     this.props.history.push(from);

    //   } else if (response.message) {
    //     this.setState({ loading: false, error: response.message });
    //   } else {
    //     this.setState({ loading: false, error: 'something went wrong' });
    //   }
    // });

    // fetch("api/Users/Login", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(body),
    //   credentials: "include",
    // })
    //   .then((res) => res.json())
    //   .then((resJson) => {
    //     if (resJson.success) {
    //       this.setState({ loading: false });
    //       setWithExpiry("user", resJson.data, 43200000); //12 hours
    //       //localStorage.setItem("user", resJson.data)
    //       const { from } = this.props.location.state || {
    //         from: { pathname: "/boss/" },
    //       };
    //       this.props.history.push(from);
    //     } else {
    //       this.setState({ loading: false, error: resJson.message });
    //     }
    //   });
  }

  render() {
    const { username, password, submitted, loading, error } = this.state;
    if (this.props.isAuthenticated) return <Redirect to="/" />;
    return (
      <div className="mt-3 pt-3 pb-3 pl-3 bg-white">
        <div className="container dark-grey-text">
          <h2>Login</h2>
          <form name="form" onSubmit={this.handleSubmit}>
            <div
              className={
                "form-group" + (submitted && !username ? " has-error" : "")
              }
            >
              <label htmlFor="username">Username</label>
              <input
                type="text"
                className="form-control"
                name="username"
                value={username}
                onChange={this.handleChange}
              />
              {submitted && !username && (
                <div className="help-block">Username is required</div>
              )}
            </div>
            <div
              className={
                "form-group" + (submitted && !password ? " has-error" : "")
              }
            >
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={this.handleChange}
              />
              {submitted && !password && (
                <div className="help-block">Password is required</div>
              )}
            </div>
            <div className="form-group">
              <button className="btn btn-primary" disabled={loading}>
                Login
              </button>
              {loading && (
                <img alt="image login loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              )}
            </div>
            {error && <div className={"alert alert-danger"}>{error}</div>}
          </form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    isAuthenticated:state.authReducer.isAuthenticated   
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password, redirect) =>{
      dispatch(login(username, password, redirect));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);