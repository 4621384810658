import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import NavBar from "../NavBar/NavBar";
import { Footer } from "../Footer/Footer";
import { SideNav } from "../Client/SideNav/SideNav";
import "./Layout.css";

export class Layout extends Component {
  static displayName = Layout.name;
  constructor() {
    super();
    this.state = { isSideNavOpen: false };

    this.handleOpenSideBar = this.handleOpenSideBar.bind(this);
    this.handleCloseSideBar = this.handleCloseSideBar.bind(this);
  }
  handleOpenSideBar() {
    //console.log("side bar open clicked", this.state);
    this.setState({ isSideNavOpen: true });
    //document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
  }
  handleCloseSideBar() {
    this.setState({ isSideNavOpen: false });
  }

  render() {
    return (
      <div>
        <div id="overlay" style={{display:this.state.isSideNavOpen ? "block" : "none"}}>
        </div>
        <SideNav
          isOpen={this.state.isSideNavOpen}
          handleClose={this.handleCloseSideBar}
        />
        <div style={{ zIndex: 1 }}>
          <NavBar handleOpenSideBarClick={this.handleOpenSideBar} />

          <div className="main-container">
            <div className="container-fluid">
              <Row>
                <Col>{this.props.children}</Col>
              </Row>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
