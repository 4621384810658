import {
  LOG_IN_REQUEST,
  LOG_OUT,
  LOG_IN_FAILURE,
  LOG_IN_SUCCESS
} from '../containts/ActionTypes';

// to set initial state when using redux, otherwise set initial state in context via useReducer
const initState = { isAuthenticated:false}

const authReducer = (state = initState, action) => {

        switch (action.type) {
          case LOG_IN_REQUEST:
            return {
              isAuthenticated: true
            };
            case LOG_IN_SUCCESS:
            return {
              isAuthenticated:true
            };
            case LOG_IN_FAILURE:
            return {
              isAuthenticated:false
            };
          case LOG_OUT:
            return {
              isAuthenticated:false
            };
          default:
            return state;
        }
      };
      
      export default authReducer;
      