import React, { useState, useEffect } from "react";
import { Table, InputGroup, FormControl } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import {generateProductNameUrl} from '../../../helpers/string-helper';

const PriceListForm = (props) => {
  const { products } = useSelector(
    (state) => ({
      products: Object.values(state.products.allProducts),
    }),
    shallowEqual
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (products && products.length) {
      const results = products.filter((item) => {
        const lc = item.name.toLowerCase();

        const filter = searchTerm.toLowerCase();

        return lc.includes(filter);
      });
      setSearchResults(results);
    }
  }, [searchTerm]);

  const renderTableRow = (data) => {
    return data.map((item) => (
      <tr key={item.id}>
        <td>
          <Link
            to={"/details/" + item.id + "/" + generateProductNameUrl(item.name)}
            style={{ color: "black" }}
          >
            {item.name}
          </Link>
        </td>
        <td>{item.brandName}</td>
        <td>
          {item.thumbnailPath ? (
            <img
              loading="lazy"
              alt={item.name}
              src={
                "https://tiemvattu.com/api/Files/GetFile?path=" +
                item.thumbnailPath
              }
              width="100px"
            ></img>
          ) : (
            ""
          )}
        </td>
        <td className="text-right">
          {item.price === 0
            ? "Liên hệ"
            : item.price.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
              })}
        </td>
      </tr>
    ));
  };

  const renderTable = (data) => {
    let headers = [
      {
        title: "Khóa chống trộm",
        types: [79, 80, 81, 82, 83, 84, 85, 86, 98, 101],
      },
      {
        title: "Dụng cụ cầm tay",
        types: [124, 106, 11, 18.76, 106, 107, 124],
      },
      {
        title: "Kềm",
        types: [87, 88, 89, 90, 91, 92, 93, 117, 123],
      },
      {
        title: "Kéo",
        types: [122, 118],
      },
      {
        title: "Dụng cụ đo đạc",
        types: [116, 120],
      },
      {
        title: "Máy khoan & Mũi khoan",
        types: [26, 121, 125],
      },
      {
        title: "Đinh vít",
        types: [16, 109, 110],
      },
      {
        title: "Tuvit",
        types: [94, 95, 96, 97],
      },
      {
        title: "Đồ làm vườn",
        types: [78],
      },
      {
        title: "Sơn",
        types: [104],
      },
      {
        title: "Sản phẩm khác",
        types: [
          0,
          114,
          28,
          19,
          20,
          21,
          23,
          24,
          25,
          59,
          77,
          99,
          100,
          105,
          108,
          114,
          115,
          111,
          113,
          112,
        ],
      },
    ];

    for (let i = 0; i < headers.length; i++) {
      headers[i].products = data.filter(
        (item) => headers[i].types.indexOf(item.typeID) > -1
      );

      if (headers[i].products === [] || headers[i].products.length === 0) {
        headers.splice(i, 1);
        i--;
      }
    }

    //console.log(productsTypes, productTypeIDs, headers);

    return headers.map((h) => (
      <>
        <tr className="bg-light">
          <td colSpan="4" className="text-center">
            <h5>
              <strong>{h.title}</strong>
            </h5>
          </td>
        </tr>
        {renderTableRow(h.products)}
      </>
    ));
  };

  return (
    <div className="mt-3 pt-3 pb-3 pl-3 pr-3 bg-white">
      <h4>Bảng giá tham khảo</h4>
      <small>
        Đây là bản giá tham khảo, nếu khách hàng mua số lượng nhiều vui lòng
        liên hệ để có giá hấp dẫn hơn.
      </small>
      <div className="pt-3 pb-3">
        <InputGroup size="lg">
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroup-sizing-lg">
              Tìm sản phẩm
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            aria-label="Large"
            value={searchTerm}
            aria-describedby="inputGroup-sizing-sm"
            onChange={handleChange}
          />
        </InputGroup>
      </div>
      {searchResults.length > 0 && (
        <small>Tìm thấy {searchResults.length} sản phẩm</small>
      )}
      <div>
        <Table responsive hover bordered size="sm">
          <thead>
            <tr>
              <th>Sản phẩm</th>
              <th>Thương hiệu</th>
              <th>Hình</th>
              <th>Giá bán (vnđ)</th>
            </tr>
          </thead>
          <tbody>
            {searchTerm ? renderTable(searchResults) : renderTable(products)}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default PriceListForm;
