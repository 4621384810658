import React, { Component } from "react";
import Dropzone from "react-dropzone";
import Cleave from "cleave.js/react";
import $ from "jquery";
import { Form, Button, Modal, ButtonToolbar } from "react-bootstrap";
import "./product.css";
import PropTypes from "prop-types";
import ProductsAPI from "../../../api/ProductsAPI";
import FilesAPI from "../../../api/FilesAPI";
import OptionListAPI from "../../../api/OptionListAPI";
import BrandAPI from "../../../api/BrandAPI";
import { getWithExpiry } from "../../../helpers/LocalStorage";
import Select, { createFilter } from "react-select";
import CreatebleSelect from "react-select/creatable";
import RichTextExample from "../../../helpers/Slate/TextEditor";

export class Product extends Component {
  static displayName = Product.name;

  constructor(props) {
    super(props);

    this.state = {
      title: "",
      loading: true,
      brandList: [],
      typeList: [],
      files: [],
      product: new ProductItem(),
      productPriceCleave: null,
      productBuyingPriceCleave: null,
      wholeSaleProductPriceCleave: null,
      modal: false,
      brandSelectedOption: null,
      categorySelectedOption: null,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.onPriceChange = this.onPriceChange.bind(this);
    this.onBuyingPriceChange = this.onBuyingPriceChange.bind(this);
    this.onProductPriceInit = this.onProductPriceInit.bind(this);
    this.onWholeSaleProductPriceInit = this.onWholeSaleProductPriceInit.bind(
      this
    );
    this.onProductBuyingPriceInit = this.onProductBuyingPriceInit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDisable = this.handleDisable.bind(this);
    this.handleEnable = this.handleEnable.bind(this);
    this.onDeletePhoto = this.onDeletePhoto.bind(this);
    this.handleCreateBrand = this.handleCreateBrand.bind(this);
    this.handleCreateCategory = this.handleCreateCategory.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.onMainPhotoChange = this.onMainPhotoChange.bind(this);
  }

  componentDidMount() {
    BrandAPI.getAll()
      .then((data) => {
        if (data) {
          let options = [];

          data.forEach((element) => {
            let option = {
              value: element.id,
              label: element.name,
            };

            options.push(option);

            // if(this.state.product.brandId > 0 && element.id == this.state.product.brandId){
            //   this.setState({brandSelectedOption:option});
            // }
          });

          //this.setState({ customerOptions: options });

          this.setState({ brandList: options });
        }
      })
      .then((a) => {
        OptionListAPI.getOptionListByKey("product-type-sub").then((data) => {
          if (data) {
            let options = [];

            data.forEach((element) => {
              let option = {
                value: element.id,
                label: element.name,
              };

              options.push(option);

              // if(this.state.product.typeID > 0 && element.id == this.state.product.typeID){
              //   this.setState({categorySelectedOption:option});
              // }
            });

            //this.setState({ customerOptions: options });

            this.setState({ typeList: options });
            //this.setState({ typeList: data });
          }
        });
      })
      .then((b) => {
        var pid = this.props.match.params["pid"];

        // This will set state for Edit product
        if (pid > 0) {
          fetch("api/Products/" + pid)
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              this.setState(
                { title: "Sửa", loading: false, product: data },
                function () {
                  //this is for product photos
                  this.setState({
                    files: this.state.product.photos.map((file) =>
                      Object.assign(file, {
                        preview:
                          "https://tiemvattu.com/api/Files/GetFile?path=" +
                          file.thumbnail,
                      })
                    ),
                  });

                  //this is for product price use cleave
                  // this.state.productPriceCleave.setRawValue(
                  //   this.state.product.price
                  // );
                  // this.state.productBuyingPriceCleave.setRawValue(
                  //   this.state.product.buyingPrice
                  // );
                  // this.state.wholeSaleProductPriceCleave.setRawValue(
                  //   this.state.product.wholeSalePrice
                  // );

                  if (data.brandID && this.state.brandList.length > 0) {
                    const selectedOption = this.state.brandList.find(
                      (x) => x.value == data.brandID
                    );
                    if (selectedOption) {
                      this.setState({ brandSelectedOption: selectedOption });
                    }
                  }
                  if (data.typeID) {
                    const selectedOption = this.state.typeList.find(
                      (x) => x.value == data.typeID
                    );
                    if (selectedOption) {
                      this.setState({ categorySelectedOption: selectedOption });
                    }
                  }
                }
              );

              // Make sure to revoke the data uris to avoid memory leaks
              //this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
            });
          $("#btnDelete").show();
        } else {
          $("#btnDelete").hide();
          this.setState({
            title: "Tạo",
            loading: false,
            files: [],
            product: new ProductItem(),
          });
        }
      });
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    // this.setState({ files: this.state.files.concat(acceptedFiles) });// concat: method is used to merge two or more arrays. This method does not change the existing arrays, but instead returns a new array.
    this.setState({
      files: acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
  };

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleEnable(e) {
    e.preventDefault();
    if (this.state.product.id === 0) {
      alert("invalid product id");
      return false;
    }
    ProductsAPI.enable(this.state.product.id).then((res) => {
      if (res.success) {
        let {product} = this.state;
        product.enabled = true;
        this.setState(product);
      }
      if (res.message) {
        alert(res.message);
      }
    });
  }

  handleCreateBrand(item) {
    //console.log('create customer', item);

    const brand = {
      name: item,
      description: "created by product page",
    };
    BrandAPI.create(brand).then((res) => {
      //console.log(res, res.id, res.name);
      if (res) {
        if (res.id > 0) {
          const selectedOption = {
            value: res.id,
            label: item,
          };

          let { brandList, brandSelectedOption } = this.state;

          brandList.push(selectedOption);
          brandSelectedOption = selectedOption;
          this.setState({ brandList, brandSelectedOption });
        }
      }
    });

    //console.log(this.state.order);
  }
  handleCreateCategory(item) {
    const option = {
      name: item,
      key: "product-type-sub",
      value: "",
    };
    OptionListAPI.create(option).then((res) => {
      //console.log(res, res.id, res.name);
      if (res) {
        if (res.id > 0) {
          const selectedOption = {
            value: res.id,
            label: item,
          };

          let { typeList, categorySelectedOption } = this.state;
          typeList.push(selectedOption);
          categorySelectedOption = selectedOption;
          this.setState({ typeList, categorySelectedOption });
        }
      }
    });
    //console.log(this.state.order);
  }
  handleDisable(e) {
    e.preventDefault();
    if (this.state.product.id === 0) {
      alert("invalid product id");
      return false;
    }

    ProductsAPI.disable(this.state.product.id).then((res) => {
      if (res.success) {
        let {product} = this.state;
        product.enabled = false;
        this.setState(product);
      }
      if (res.message) {
        alert(res.message);
      }
    });
  }

  handleDelete(event) {
    if (this.state.product.id === 0) {
      alert("invalid product id");
      return false;
    }

    //alert("deleted");
    let r = window.confirm("Bạn có chắc muốn xóa sản phẩm này?");

    if (r == true) {
      const token = getWithExpiry("user");

      fetch(`/api/Products/${this.state.product.id}`, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
          accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.success) {
            this.props.history.push("/boss/fetch-product");
          } else {
            alert(responseJson.message);
          }
        })
        .catch((err) => console.log("error", err));
    }
    //this.handleClose
  }

  handleBrandChange(id, name) {
    const selectedOption = {
      value: id,
      label: name,
    };
    let product = this.state.product;
    product.brandID = id;
    this.setState({ brandSelectedOption: selectedOption, product: product });
  }
  handleCategoryChange(id, name) {
    const selectedOption = {
      value: id,
      label: name,
    };
    let product = this.state.product;
    product.typeID = id;
    this.setState({ categorySelectedOption: selectedOption, product: product });
  }

  handleChange(event) {
    const { name, value, type, checked, rawValue } = event.target;

    let item = this.state.product;

    console.log(item[name], rawValue, value);

    if (name === "price" || name === "buyingPrice" || name === "wholeSalePrice") {
      if(rawValue > 0){
        item[name] = parseFloat(rawValue);

        if(name === "buyingPrice"){
          if(!item.price || item.price < parseFloat(rawValue)){
            //suggestion for price base on cost +20%
            const suggestionPrice = parseFloat(rawValue) + (parseFloat(rawValue)*0.2);
            item.price = suggestionPrice;
          }
          if(!item.wholeSalePrice || item.wholeSalePrice < parseFloat(rawValue)){
            //suggestion whole sale price base on cost +10%
            const suggestionWholeSalePrice = parseFloat(rawValue) + (parseFloat(rawValue)*0.1);
            item.wholeSalePrice = suggestionWholeSalePrice;
          }
        }
      }
      else{
        item[name] = 0;
      }
    } else if (
      name === "length" ||
      name === "width" ||
      name === "weight" ||
      name === "sizeInch" ||
      name === "sizeMM" ||
      name === "height"
    ) {
      item[name] = parseFloat(value);
    } else if (name === "brandID" || name === "typeID") {
      item[name] = parseInt(value);
    } else {
      item[name] = value;
    }

    this.setState({
      product: item,
    });
    //console.log('product', item);
  }

  // This will handle the submit form event.
  handleSave(event) {
    event.preventDefault();
    const form = event.target;
    //console.log(form);
    const data = new FormData(form);

    if (this.state.files.length > 0) {
      data.append("files", this.state.files);
    }

    const token = getWithExpiry("user");

    const descLocalStorageName = "content-" + this.state.product.id;
    let productDescJSON = localStorage.getItem(descLocalStorageName);

    let bodyData = {
      name: this.state.product.name,
      wholeSalePrice:
        this.state.product.wholeSalePrice > 0
          ? parseFloat(this.state.product.wholeSalePrice)
          : 0,
      price:
        this.state.product.price > 0 ? parseFloat(this.state.product.price) : 0,
      buyingPrice:
        this.state.product.buyingPrice > 0
          ? parseFloat(this.state.product.buyingPrice)
          : 0,
      productcode: this.state.product.productCode,
      description: productDescJSON,
      typeID: this.state.product.typeID > 0 ? this.state.product.typeID : 0,
      brandID: this.state.product.brandID > 0 ? this.state.product.brandID : 0,
      sku: this.state.product.sku,
      sizeInch:
        this.state.product.sizeInch > 0
          ? parseFloat(this.state.product.sizeInch)
          : 0,
      sizeMM:
        this.state.product.sizeMM > 0 ? parseInt(this.state.product.sizeMM) : 0,
      weight:
        this.state.product.weight > 0 ? parseInt(this.state.product.weight) : 0,
      width:
        this.state.product.width > 0 ? parseInt(this.state.product.width) : 0,
      length:
        this.state.product.length > 0 ? parseInt(this.state.product.length) : 0,
      height:
        this.state.product.height > 0 ? parseInt(this.state.product.height) : 0,
    };

    if (!bodyData.name) {
      alert("Vui lòng nhập tên sản phẩm");
      return;
    }
    if (!bodyData.price === 0) {
      alert("Vui lòng nhập giá sản phẩm");
      return;
    }
    if (
      bodyData.price > 0 &&
      bodyData.buyingPrice > 0 &&
      bodyData.price <= bodyData.buyingPrice
    ) {
      alert("Giá bán ra không được thấp hơn hoặc bằng giá nhập");
      return;
    }

    let requestBody = JSON.stringify(bodyData);

   //console.log(requestBody);

    // PUT request for Edit product.
    if (this.state.product.id) {
      fetch("api/Products/" + this.state.product.id, {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + token,
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: requestBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          //console.log(responseJson, this.state.files.length);
          if (responseJson.success) {
            if (this.state.files.length > 0) {
              fetch(`api/Files/${this.state.product.id}/product`, {
                headers: {
                  Authorization: "Bearer " + token,
                },
                method: "POST",
                body: data,
              }).then((response) =>
                response.ok
                  ? this.props.history.push("/boss/fetch-product")
                  : console.log(response)
              );
            } else {
              this.props.history.push("/boss/fetch-product");
            }
          } else {
            //alert(responseJson);
            console.log(responseJson);
            alert(responseJson.message);
          }
        });
    }
    // POST request for Add product.
    else {
      fetch("api/Products/", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: requestBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          //console.log(responseJson, this.state.files.length);
          if (responseJson.success) {
            if (this.state.files.length > 0 && responseJson.product_id > 0) {
              fetch(`api/Files/${responseJson.product_id}/product`, {
                headers: {
                  Authorization: "Bearer " + token,
                },
                method: "POST",
                body: data,
              }).then((response) =>
                response.ok
                  ? this.props.history.push("/boss/fetch-product")
                  : console.log(response)
              );
            } else {
              this.props.history.push("/boss/fetch-product");
            }
          } else {
            console.log(responseJson);
            alert(responseJson.message);
          }
        })
        .catch((err) => console.log("catch", err));
    }
  }

  handleBack(event) {
    this.props.history.goBack();
  }

  onPriceChange(event) {
    let p = this.state.product;
    p.price = event.target.rawValue;
    //console.log(this.state.product);
    this.setState({ product: p });
    //console.log(this.state.product);
  }

  onBuyingPriceChange(event) {
    let p = this.state.product;
    p.buyingPrice = event.target.rawValue;
    this.setState({ product: p });
  }

  onProductPriceInit(cleave) {
    this.setState({ productPriceCleave: cleave });
  }
  onWholeSaleProductPriceInit(cleave) {
    this.setState({ wholeSaleProductPriceCleave: cleave });
  }
  onProductBuyingPriceInit(cleave) {
    this.setState({ productBuyingPriceCleave: cleave });
  }

  // toggleHover() {
  //     console.log('hover ');
  // }

  onDeletePhoto(fileId, fileIndex) {
    //console.log('delete photo', fileId, fileIndex, files);

    if (!fileId && fileIndex < 0) {
      return false;
    }

    let files = this.state.files;

    files.splice(fileIndex, 1);

    this.setState({ files: files }, () => {
      //console.log("deleted", this.state.files);
    });

    if (fileId) {
      FilesAPI.deleteFileById(fileId).then((res) => {
        if (res.success) {
          alert("Photo has been deleted successfully");
        } else {
          alert("Something error. Could not delete photo");
        }
      });
    }
  }

  onMainPhotoChange(e){
    const {product} = this.state;
    if(!e.target.value || !product.id){
      return;
    }
    //console.log('on main photo change', e.target.value, product.id);
    const payload = {
      fileId:e.target.value,
      recordId:product.id
    }
    FilesAPI.updateMainPhoto(payload).then(res => {
      if(res && res.success){
        console.log('success');
      }else{
        console.log('error')
      }
    })
  }

  static renderCreateForm(props) {
    /*start dropzone style*/
    const baseStyle = {
      width: 200,
      height: 200,
      borderWidth: 2,
      borderColor: "#666",
      borderStyle: "dashed",
      borderRadius: 5,
    };
    const activeStyle = {
      borderStyle: "solid",
      borderColor: "#6c6",
      backgroundColor: "#eee",
    };
    const rejectStyle = {
      borderStyle: "solid",
      borderColor: "#c66",
      backgroundColor: "#eee",
    };
    /*end dropzone style*/
    const filterConfig = {
      ignoreCase: true,
      ignoreAccesnt: true,
      trim: true,
      matchForm: "any",
    };

    const thumbs = props.state.files.map((file, index) => (
      <div className="thumb" onMouseEnter={props.toggleHover} key={file.id}>
        <div className="thumbInner">
          <span
            className="thumb-delete"
            onClick={(fileId) => props.onDeletePhoto(file.id, index)}
          >
            ×
          </span>
          <img src={file.preview} className="img" alt={file.original} />
        </div>
        <input type="radio" className="thumb-radio" name="mainPhoto" value={file.id} onChange={props.onMainPhotoChange}></input>
      </div>
    ));
    return (
      <div className="container">
        <Form onSubmit={props.handleSave}>
          <div className="form-group row">
            <input
              type="hidden"
              name="id"
              defaultValue={props.state.product.id}
            />
          </div>

          <div className="form-group row">
            <Dropzone name="files" accept="image/*" onDrop={props.onDrop}>
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragAccept,
                isDragReject,
                acceptedFiles,
                rejectedFiles,
              }) => {
                let styles = { ...baseStyle };
                styles = isDragActive ? { ...styles, ...activeStyle } : styles;
                styles = isDragReject ? { ...styles, ...rejectStyle } : styles;

                return (
                  <div {...getRootProps()} style={styles}>
                    <input {...getInputProps()} />
                    <div>{isDragAccept ? "Drop" : "Drag"} Hình sản phẩm...</div>
                    {isDragReject && <div>Unsupported file type...</div>}
                  </div>
                );
              }}
            </Dropzone>
          </div>

          {props.state.files.length > 0 && (
            <aside className="thumbsContainer">{thumbs}</aside>
          )}
          <div className="form-group row">
            <label htmlFor="txtName" className="col-md-2">
              Tên sản phẩm
            </label>
            <div className="col-md-6">
              <input
                type="text"
                id="txtName"
                name="name"
                className="form-control"
                defaultValue={props.state.product.name}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtPrice" className="col-md-2">
              Giá bán lẻ
            </label>
            <div className="col-md-6">
              <Cleave
                name="price"
                className="input-numereal form-control"
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                }}
                onChange={props.handleChange}
                onInit={props.onProductPriceInit}
                value={props.state.product.price}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtWholeSalePrice" className="col-md-2">
              Giá bán sỉ
            </label>
            <div className="col-md-6">
              <Cleave
                name="wholeSalePrice"
                id="txtWholeSalePrice"
                className="input-numereal form-control"
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                }}
                onChange={props.handleChange}
                onInit={props.onWholeSaleProductPriceInit}
                value={props.state.product.wholeSalePrice}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtBuyingPrice" className="col-md-2">
              Giá vốn
            </label>
            <div className="col-md-6">
              <Cleave
                name="buyingPrice"
                id="txtBuyingPrice"
                className="input-numereal form-control"
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                }}
                onChange={props.handleChange}
                onInit={props.onProductBuyingPriceInit}
                value={props.state.product.buyingPrice}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtBuyingPrice" className="col-md-2">
              Nhà cung cấp
            </label>
            <div className="col-md-6">
            <Select
            isMulti
            name="suppliers"
            closeMenuOnSelect={false}
            options={props.suppliersOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={props.handleSuppliersChange}
            value={props.selectedSuppliersOptions}
          />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="comboBrand" className="col-md-2">
              Thương hiệu
            </label>
            <div className="col-md-6">
              {/* <select
                className="form-control"
                name="brandID"
                value={props.state.product.brandID}
                onChange={props.handleChange}
                required
              >
                <option value="0">--Chọn thương hiệu--</option>
                {props.state.brandList.map((i) => (
                  <option key={i.id} value={i.id}>
                    {i.name}
                  </option>
                ))}
              </select> */}
              <CreatebleSelect
                value={props.state.brandSelectedOption}
                options={props.state.brandList}
                isSearchable
                onChange={(opt) =>
                  props.handleBrandChange(opt.value, opt.label)
                }
                onCreateOption={(opt) => props.handleCreateBrand(opt)}
                filterOption={createFilter(filterConfig)}
              ></CreatebleSelect>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="comboBrand" className="col-md-2">
              Loại sản phẩm
            </label>
            <div className="col-md-6">
              {/* <select
                className="form-control"
                name="typeID"
                value={props.state.product.typeID}
                onChange={props.handleChange}
                required
              >
                <option value="0">--Chọn loại sản phẩm--</option>
                {props.state.typeList.map((i) => (
                  <option key={i.id} value={i.id}>
                    {i.name}
                  </option>
                ))}
              </select> */}
              <CreatebleSelect
                value={props.state.categorySelectedOption}
                options={props.state.typeList}
                isSearchable
                onChange={(opt) =>
                  props.handleCategoryChange(opt.value, opt.label)
                }
                onCreateOption={(opt) => props.handleCreateCategory(opt)}
                filterOption={createFilter(filterConfig)}
              ></CreatebleSelect>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtDescription" className="col-md-2">
              Giới thiệu sản phẩm
            </label>
            <div className="col-md-6">
              <div className="rich-text-editor">
                <RichTextExample
                  id={props.state.product.id}
                  content={props.state.product.description}
                />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtProductCode" className="col-md-2">
              Mã sản phẩm từ nhà sản xuất
            </label>
            <div className="col-md-6">
              <input
                type="text"
                id="txtProductCode"
                name="productCode"
                className="form-control"
                defaultValue={props.state.product.productCode}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtSKU" className="col-md-2">
              Mã SKU
            </label>
            <div className="col-md-6">
              <input
                type="text"
                id="txtSKU"
                name="sku"
                className="form-control"
                defaultValue={props.state.product.sku}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtWeight" className="col-md-2">
              Trọng lượng (gram)
            </label>
            <div className="col-md-6">
              <input
                type="number"
                min={0}
                id="txtWeight"
                name="weight"
                className="form-control"
                defaultValue={props.state.product.weight}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtSizeInch" className="col-md-2">
              Kích cỡ Inch
            </label>
            <div className="col-md-6">
              <input
                type="number"
                min={0}
                id="txtSizeInch"
                name="sizeInch"
                className="form-control"
                defaultValue={props.state.product.sizeInch}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtSizeMM" className="col-md-2">
              Kích cỡ MM
            </label>
            <div className="col-md-6">
              <input
                type="number"
                min={0}
                id="txtSizeMM"
                name="sizeMM"
                className="form-control"
                defaultValue={props.state.product.sizeMM}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtHeight" className="col-md-2">
              Chiều cao (cm)
            </label>
            <div className="col-md-6">
              <input
                type="number"
                min={0}
                id="txtHeight"
                name="height"
                className="form-control"
                defaultValue={props.state.product.height}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtWidth" className="col-md-2">
              Chiều rộng (cm)
            </label>
            <div className="col-md-6">
              <input
                type="number"
                min={0}
                id="txtWidth"
                name="width"
                className="form-control"
                defaultValue={props.state.product.width}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtLength" className="col-md-2">
              Chiều dài (cm)
            </label>
            <div className="col-md-6">
              <input
                type="number"
                min={0}
                id="txtLength"
                name="length"
                className="form-control"
                defaultValue={props.state.product.length}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <div>
              <Button type="submit" className="btn btn-primary mr-2">
                Lưu
              </Button>

              <Button
                variant="outline-dark"
                onClick={props.handleBack}
                className="mr-2"
              >
                Thoát
              </Button>
              {props.state.product.id > 0 ? (
                <Button
                  variant="outline-danger"
                  id="btnDelete"
                  className="mr-2"
                  onClick={props.handleDelete}
                >
                  Xóa
                </Button>
              ) : (
                ""
              )}

              {props.state.product.id > 0 ? (
                props.state.product.enabled ? (
                  <Button
                    id="btnDelete"
                    variant="outline-warning"
                    onClick={props.handleDisable}
                  >
                    Ẩn Sản Phẩm
                  </Button>
                ) : (
                  <Button
                    variant="outline-success"
                    id="btnDelete"
                    onClick={props.handleEnable}
                  >
                    Bật Sản Phẩm
                  </Button>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </Form>
      </div>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <p>
        <em>Loading...</em>
      </p>
    ) : (
      Product.renderCreateForm(this)
    );
    return (
      <div className="mt-3 pt-3 pb-3 pl-3 pr-3 bg-white">
        <h2>{this.state.title} Sản Phẩm</h2>
        <hr />
        {contents}
        {/* <Modal show={this.state.show} onHide={this.handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>{this.state.product.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>Bạn đang xóa sản phẩm này, bạn có chắc không?</Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={this.handleDelete}>Yes</Button>{' '}
                <Button variant="secondary" onClick={this.handleClose}>Cancel</Button>
            </Modal.Footer>
        </Modal> */}
      </div>
    );
  }
}

export class ProductItem {
  id = 0;
  name = "";
  wholeSalePrice = 0;
  price = 0;
  buyingPrice = 0;
  brandID = 0;
  typeID = 0;
  description = "";
  productCode = "";
  sku = "";
  weight = 0;
  sizeInch = 0;
  sizeMM = 0;
  height = 0;
  width = 0;
  length = 0;
  enabled = false;
}

Product.propTypes = {
  name: PropTypes.string,
  wholeSalePrice: PropTypes.number,
  price: PropTypes.number,
  buyingPrice: PropTypes.number,
  weight: PropTypes.number,
  height: PropTypes.number,
  sizeInch: PropTypes.number,
  sizeMM: PropTypes.number,
  length: PropTypes.number,
  typeID: PropTypes.number,
  brandID: PropTypes.number,
  enabled: PropTypes.bool,
};
