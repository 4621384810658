import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./product-card.css";
import {generateProductNameUrl} from '../../helpers/string-helper';

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = { products: [], keyword: "", query: props.query };
  }

  handleClick(id) {
    this.props.history.push("/details/" + id);
  }

  render() {
    let tempPath = "/img/product/notfound.webp";
    let contents =
      this.props.products.length > 0
        ? this.props.products.map((item) => {
            return (
              <Card key={item.id} body={true} border={"light"}>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to={'/details/'+item.id+'/'+generateProductNameUrl(item.name)}
                >
                  <Card.Img
                    className="card-img-top img-fluid"
                    variant="top"
                    loading="lazy"
                    alt={item.imageAlt}
                    src={
                      item.thumbnailPath
                        ? "https://tiemvattu.com/api/Files/GetFile?path=" +
                          item.thumbnailPath
                        : tempPath
                    }
                    alt={item.name}
                  />
                  <Card.Body>
                    <div className="card-text">
                      {item.name}
                    </div>
                  </Card.Body>
                </Link>
              </Card>
            );
          })
        : "No products found";

    return (
      <div className="mt-3 pt-3 pl-3 bg-white">
        <h2>Kết quả tìm kiếm "{this.props.match.params["query"]}":</h2>
        <div className="container dark-grey-text">
          <div className="card-columns">
            {contents}
          </div>
        </div>

      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  let allProducts = [];
  allProducts = Object.values(state.products.allProducts);
  let products = [];
  const query = ownProps.match.params.query;
  if (allProducts && allProducts.length) {
    allProducts.map((item) => item.name.toLowerCase().includes(query.toLowerCase()) && products.push(item));
  }
  //console.log(products);
  return { products };
};

export default connect(mapStateToProps, {})(Search);
