import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./SideNav.css";
import OptionListAPI from "../../../api/OptionListAPI";

export class SideNav extends Component {
  constructor(props) {
    super(props);
    //console.log("side nav props", props);
    this.state = {
      isOpen: this.props.isOpen,
      isClosed: false,
      categories: [],
      subCategories: [],
      openCategories: [],
    };
    //this.closeNav = this.closeNav.bind(this);
    this.handleOpenSubCategories = this.handleOpenSubCategories.bind(this);
  }

  componentDidMount() {
    //something here
    OptionListAPI.getOptionListByKey("product-type").then((data) => {
      if (data) {
        //console.log(data);
        this.setState({ categories: data });
      }
    });
    OptionListAPI.getOptionListByKey("product-type-sub").then((data) => {
      if (data) {
        //console.log(data);
        this.setState({ subCategories: data });
      }
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log("derived state from props", prevState.isOpen, nextProps.isOpen);
    if (prevState.isOpen !== nextProps.isOpen) {
      return { isOpen: nextProps.isOpen };
    } else {
      return null;
    }
    //console.log("derived state from props", prevState.isOpen, nextProps.isOpen);
  }
  componentDidUpdate(prevProps, prevState) {
    //console.log("component did update", prevState.isOpen, prevProps.isOpen);
    if (prevState.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: prevState.isOpen });
    }
  }

  handleOpenSubCategories(id) {
    let openCategories = this.state.openCategories;
    //console.log('open sub cat')

    if (openCategories.includes(id)) {
      const index = openCategories.indexOf(id);
      openCategories.splice(index, 1);
    } else {
      openCategories.push(id);
    }

    this.setState({ openCategories: openCategories });
  }

  static renderCategoryIcon(id) {
    switch (id) {
      case 2:
        return <i className="fas fa-utensils"></i>;
      case 3:
        return <i className="fas fa-bath"></i>;
      case 4:
        return <i className="fas fa-rocket"></i>;
      case 5:
        return <i className="fas fa-toolbox"></i>;
      case 6:
        return <i className="fas fa-user-shield"></i>;
      case 7:
        return <i className="far fa-bell"></i>;
      case 8:
        return <i className="fas fa-motorcycle"></i>;
      case 9:
        return <i className="fab fa-pagelines"></i>;
      case 10:
        return <i className="fas fa-plug"></i>;
      case 13:
      case 17:
        return <i className="fas fa-tools"></i>;
      case 14:
        return <i className="fas fa-mail-bulk"></i>;
      case 30:
        return <i className="fas fa-lock"></i>;
      case 103:
        return <i className="fas fa-ruler-combined"></i>;
      case 60:
        return <i className="fas fa-hammer"></i>;
      case 102:
        return <i className="fas fa-cog"></i>;
      default:
        return <i className="fas fa-angle-double-right"></i>;
    }
  }

  static renderSubCategories(
    id,
    name,
    data,
    openSubCategories,
    openCategories,
    handleClose
  ) {
    const active = openCategories.includes(id);
    //console.log('active',active, openCategories);
    const icon = SideNav.renderCategoryIcon(id);
    const subCatIcon = <i className="fas fa-angle-right"></i>;
    return (
      <div key={id}>
        <button
          className={active ? "dropdown-btn active" : "dropdown-btn"}
          onClick={(x) => openSubCategories(id)}
        >
          {icon}
          {name}
          <i className="fa fa-caret-down"></i>
        </button>
        <div
          className="dropdown-container"
          style={active ? { display: "block" } : { display: "none" }}
        >
          {data.map((s) => {
            return (
              <Link
                key={s.id}
                style={{ color: "#aaaaaa" }}
                to={"/category/" + s.id + "/" + s.name}
                onClick={handleClose}
              >
                {subCatIcon}
                {s.name}
              </Link>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        id="mySidenav"
        style={
          this.state.isOpen 
            ? (window.innerWidth <= 760 ? { width: "100%", zIndex: 18 } : { width: "350px", zIndex: 18 })
            : { width: "0px", zIndex: 18 }
        }
        className="sidenav"
      >
        <a href="#top" className="closebtn" onClick={this.props.handleClose}>
          &times;
        </a>
        {this.state.categories.map((item) => {
          let sub = this.state.subCategories.filter(
            (x) => x.recordId && x.recordId === item.id
          );
          if (sub && sub.length > 0) {
            const subContent = SideNav.renderSubCategories(
              item.id,
              item.name,
              sub,
              this.handleOpenSubCategories,
              this.state.openCategories,
              this.props.handleClose
            );
            return subContent;
          } else {
            const icon = SideNav.renderCategoryIcon(item.id);
            return (
              <Link
                key={item.id}
                style={{ color: "#aaaaaa" }}
                to={"/category/" + item.id + "/" + item.name}
                onClick={this.props.handleClose}
              >
                {icon}
                {item.name}
              </Link>
            );
          }
        })}
      </div>
    );
  }
}
