import React,{Component} from "react";
import PriceListForm from "./PriceListForm";

export class PriceList extends Component {
  render(){    
    return(
      <div>
        <PriceListForm />
      </div>      
    );
  }
}