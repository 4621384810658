import Config from "../Config";
import { getWithExpiry } from "../helpers/LocalStorage";

const ProductsAPI = {
  getAll,
  getSuggestions,
  create,
  edit,
  disable,
  enable,
  getProductsForAdmin,
  getProductsHomePage,
  getSellingProducts,
  getProductPrices,
};

async function getAll(records, enabled) {
  try {
    const url =
      Config.apiUrl + "Products/?records=" + records + "&enabled=" + enabled;
    let response = await fetch(url);
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

async function getProductsHomePage() {
  try {
    const url = Config.apiUrl + "Products/HomePage";
    let response = await fetch(url);
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

async function getSellingProducts() {
  try {
    const url = Config.apiUrl + "Products/SellingProducts";
    let response = await fetch(url);
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}
async function getProductPrices(keyword) {
  try {
    const url = Config.apiUrl + "Products/CheckPrices/?keyword=" + keyword;

    let response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}
async function getProductsForAdmin() {
  try {
    const url = Config.apiUrl + "Products/ProductsAdmin/";

    let response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

async function getSuggestions(customerId) {
  try {
    const url = Config.apiUrl + "products/ProductSuggestions/" + customerId;
    let response = await fetch(url);
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

async function create(item) {
  try {
    let response = await fetch(Config.apiUrl + "products/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(item),
    });

    let data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
  }
}

async function edit(item) {
  try {
    const token = getWithExpiry("user");

    if (!token) {
      console.log("no token found");
      return null;
    }
    let response = await fetch(Config.apiUrl + "products/" + item.id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(item),
    });

    let data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
  }
}

async function disable(id) {
  try {
    let response = await fetch(Config.apiUrl + "products/disable/" + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    let data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
  }
}

async function enable(id) {
  try {
    let response = await fetch(Config.apiUrl + "products/enable/" + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    let data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
  }
}

export default ProductsAPI;
