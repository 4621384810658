import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getCartProducts, getTotal } from "../../../reducers";
import {
  checkout,
  removeItem,
  addQuantity,
  subtractQuantity
} from "../../../actions/products";
import { Button } from "react-bootstrap";
import "./cart.css";

class Cart extends Component {
  constructor(props) {
    super(props);

    this.handleCheckout = this.handleCheckout.bind(this);
  }

  handleCheckout() {
    this.props.history.push("/checkout");
  }

  render() {
    const hasProducts = this.props.products.length > 0;
    const products = this.props.products;
    //console.log("cart product", products);
    const nodes = hasProducts ? (
      products.map(product => (
        <li className="collection-item avatar" key={product.id}>
          <div className="item-img">
            <img
              style={{ maxHeight: 100 }}
              src={"https://tiemvattu.com/api/Files/GetFile?path=" + product.thumbnailPath}
              alt={product.thumbnailPath}
              className=""
            />
          </div>

          <div className="item-desc">
            <span className="title">{product.name}</span>
            <div className="add-remove">
              <b>
                Đơn Giá:{" "}
                {product.price.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0
                })}
                đ
              </b>{" "}
              &nbsp;
              <b>Số lượng: {product.quantity}</b> &nbsp;
              <Link to="/cart">
                <i
                  className="fas fa-plus"
                  onClick={id => {
                    this.props.addQuantity(product.id);
                  }}
                ></i>
              </Link>{" "}
              &nbsp;
              <Link to="/cart">
                <i
                  className="fas fa-minus"
                  onClick={id => {
                    this.props.subtractQuantity(product.id);
                  }}
                ></i>
              </Link>{" "}
              &nbsp;
              <Link to="/cart">
                <i
                  className="fas fa-trash-alt"
                  onClick={id => {
                    this.props.removeItem(product.id);
                  }}
                ></i>
              </Link>
            </div>
          </div>
        </li>
      ))
    ) : (
      <em>
        Bạn chưa chọn mua sản phẩm nào hết á <span role="img">🙄</span>
      </em> /*https://getemoji.com/*/
    );

    return (
      <div className="mt-3 pb-3 pt-3 pl-3 bg-white">
        <div className="container">
          <h3>Giỏ hàng của bạn</h3>
          <div className="cart">
            <ul className="collection">{nodes}</ul>
          </div>
          <h4>Tổng cộng: {this.props.total.toLocaleString(navigator.language, {
                          minimumFractionDigits: 0,
                        })}đ</h4>
          <Button
            variant="success"
            onClick={this.handleCheckout}
            disabled={hasProducts ? "" : "disabled"}
          >
            Đặt hàng
          </Button>
        </div>
      </div>
    );
  }
}

//const Cart = ({ products, total, checkout, addQuantity, subtractQuantity, removeItem }) => {
//    const hasProducts = products.length > 0
//    const nodes = hasProducts ? (
//        products.map(product =>
//            <li className="collection-item avatar" key={product.id}>
//                <div className="item-img">
//                    <img style={{ maxHeight: 100 }} src={product.thumbnailPath} alt={product.thumbnailPath} className="" />
//                </div>

//                <div className="item-desc">
//                    <span className="title">{product.name}</span>
//                    <div className="add-remove">
//                        <b>Đơn Giá: {product.price.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}đ</b>	&nbsp;
//                                        <b>Số lượng: {product.quantity}</b> 	&nbsp;
//                                            <Link to="/cart"><i className="fas fa-plus" onClick={(id) => { addQuantity(product.id) }}></i></Link>	&nbsp;
//                                            <Link to="/cart"><i className="fas fa-minus" onClick={(id) => { subtractQuantity(product.id) }}></i></Link>	&nbsp;
//                                            <Link to="/cart"><i className="fas fa-trash-alt" onClick={(id) => { removeItem(product.id) }}></i></Link>
//                    </div>
//                </div>
//            </li>
//        )
//    ) : (
//            <em>Bạn chưa chọn mua sản phẩm nào hết á <span role='img'>🙄</span></em>/*https://getemoji.com/*/
//        )

//    Product.propTypes = {
//        total: PropTypes.number,
//    }

//    return (
//        <div className="container">
//            <h3>Giỏ hàng của bạn</h3>
//            <div className="cart"><ul className="collection">{nodes}</ul></div>
//            <h4>Tổng cộng: {total}đ</h4>
//            <Button variant="success" onClick={() => checkout(products)} disabled={hasProducts ? '' : 'disabled'}>
//                Đặt hàng
//      </Button>

//        </div>
//    )
//}

const mapStateToProps = state => {
  return {
    products: getCartProducts(state),
    total: getTotal(state).toLocaleString(navigator.language, {
      minimumFractionDigits: 0
    })
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeItem: id => {
      dispatch(removeItem(id));
    },
    addQuantity: id => {
      dispatch(addQuantity(id));
    },
    subtractQuantity: id => {
      dispatch(subtractQuantity(id));
    }
    //checkout: (products) => { dispatch(checkout(products)) }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
