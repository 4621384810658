import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Footer.css";

export class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="col-sm-12 pt-3">
          <Row className="mt-3">
            <Col xs="4" className="text-center">
              <a
                style={{ color: "white" }}
                href="https://shopee.vn/trqtrung"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mua trên Shopee
              </a>
            </Col>
            <Col xs="4" className="text-center">
              <a
                style={{ color: "white" }}
                href="https://www.sendo.vn/shop/tiemvattu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mua trên Sendo
              </a>
            </Col>
            <Col xs="4" className="text-center">
              <a
                style={{ color: "white" }}
                href="https://www.lazada.vn/shop/cho-vat-tu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mua trên Lazada
              </a>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="text-center">
              <p style={{ color: "white" }}>
                Địa chỉ: 19 Trịnh Hoài Đức, Phường 13, Quận 5, TP.Hồ Chí Minh
              </p>
              <p style={{ color: "white" }}>
                Số điện thoại:{" "}
                <a style={{ color: "white" }} href="tel:0768616869">
                  07.6861.6869
                </a> - 
                <a style={{ color: "white" }} href="tel:0283856779">
                  028.3856.7769
                </a>
              </p>
              <p style={{ color: "white" }}>
                Email:{" "}
                <a style={{ color: "white" }} href="mailto:tiemvattu@gmail.com">
                  tiemvattu@gmail.com
                </a>
              </p>
              
            </Col>
          </Row>
          <Row className="bottom-row">
          <Col className="text-center">
          <p style={{ color: "white" }}>
                Developed by <a style={{color:"white"}} rel="noopener noreferrer" target="_blank" href="https://trqtrung.github.io/porfolio/">Trung</a> - Copyright © {new Date().getFullYear()}
              </p>
            </Col>
          </Row>
        </div>
      </footer>
    );
  }
}
