import Config from "../Config";
import { getWithExpiry } from "../helpers/LocalStorage";

const CustomerAPI = {
  getAll,
  create,
  edit,
};

async function getAll() {
  try {
    const url = Config.apiUrl + "Customers/";

    const response = await fetch(url, {
      method: "GET",
      headers: {               
        'Content-Type': 'application/json'
    },
    credentials: "include",
    });

    return await response.json();
  } catch (err) {
    console.log(err);
  }
}

async function create(item) {
  const url = Config.apiUrl + "Customers";

  const payload = JSON.stringify(item);

  const response = await fetch(url, {
    method: "POST",
    headers: {               
      'Content-Type': 'application/json'
  },
  credentials: "include",
    body: payload,
  });

  return await response.json();
}

async function edit(item, id) {
  const url = Config.apiUrl + "Customers/" + id;

  const payload = JSON.stringify(item);

  const response = await fetch(url, {
    method: "PUT",
    headers: {               
      'Content-Type': 'application/json'
  },
  credentials: "include",
    body: payload,
  });

  return await response.json();
}

export default CustomerAPI;
