import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
//import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import reducer from './reducers';
import { getAllProducts } from './actions/products';

//react-image-gallery style webpack https://github.com/xiaolin/react-image-gallery
import "react-image-gallery/styles/css/image-gallery.css";

// // setup fake backend
// import { configureFakeBackend } from './helpers/fake-backend';
// configureFakeBackend();

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


const store = createStore(
  reducer,
  applyMiddleware(thunk)
)

store.dispatch(getAllProducts())


ReactDOM.render(
<Provider store={store}><BrowserRouter basename={baseUrl}>
    <App />
    </BrowserRouter>
    </Provider>,
  rootElement
)

// ReactDOM.render(
//   <BrowserRouter basename={baseUrl}>
//     <Provider store={store}
//     <App />
//     </Provider>
//   </BrowserRouter>,
//   rootElement);

registerServiceWorker();
