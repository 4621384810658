import React, { useState, useEffect } from "react";
import { Table, Button, Form, Col } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import Select, { createFilter } from "react-select";
import Cleave from "cleave.js/react";
import { useHistory } from "react-router-dom";
import OrderApi from "../../../api/OrdersAPI";
import { FormatDate } from "../../../helpers/date-time";
import useModal from "../../../helpers/Modal/useModal";
import ConfirmModal from "../../../helpers/Modal/ConfirmModal";

const AddOrderForm = (props) => {
  const initOrder = {
    id: 0,
    websiteId: "",
    orderId: "",
    total: 0,
    orderDate: FormatDate(new Date()),
    customerName: "",
    customerPhone: "",
    deliveryAddress: "",
    stage: "new",
    orderItems: [],
  };

  const initProduct = {
    id: 0,
    productId:0,
    name: "",
    price: 0,
    buyingPrice: 0,
    total: 0,
    quantity: 1,
    unit: 61, //cái as default for unit
    selectedOption: null,
    orderItemId: 0,
  };

  const history = useHistory();

  //get products from redux state
  const { products } = useSelector(
    (state) => ({
      products: Object.values(state.products.allProducts),
    }),
    shallowEqual
  );

  const [order, setOrder] = useState(initOrder);
  const [productOptions, setProductOptions] = useState([]);
  const [orderItems, setOrderItems] = useState([initProduct]);
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    //console.log("use effect products");
    if (products.length > 0 && productOptions.length == 0) {
      //console.log("set product options");
      let options = [];

      products.forEach((element) => {
        let option = {
          value: element.id,
          label: element.name,
          price: element.price,
        };

        options.push(option);
      });
      setProductOptions(options);
      //console.log('option',productOptions);
    }
  }, [products]);

  useEffect(() => {
    //console.log("use effect id query");
    if (props.id > 0) {
      //fetching order
      OrderApi.getOnlineOrderById(props.id).then((res) => {
        //console.log("get online order by id", res);
        if (res) {
          let items = [];
          res.orderItems.forEach((element) => {
            const selectedOption = {
              value: element.productId,
              label: element.productName,
            };
            element.selectedOption = selectedOption;
            items.push(element);
          });

          setOrderItems(items);

          setOrder({
            id: res.id,
            websiteId: res.websiteId,
            orderId: res.orderId,
            orderDate: FormatDate(res.orderDate),
            total: res.total,
            customerName: res.customerName,
            customerPhone: res.customerPhone,
            deliveryAddress: res.deliveryAddress,
            stage: res.stage,
            orderItems: items,
          });
        } else {
        }
      });
    }
  }, [props.id]);

  const handleProductChange = (name, value, price, index) => {
    //console.log("change product", name, value, index, orderItems);
    let items = [...orderItems];
    items[index].productId = value;
    items[index].name = name;
    items[index].price = price > 0 ? parseFloat(price) : 0;
    items[index].selectedOption = {
      value: value,
      label: name,
    };
    setOrderItems(items);
    //console.log("product change", name, value, index, orderItems);
  };

  const handleProductQtyChange = (value, index) => {
    //console.log('qty change', value, index)
    let items = [...orderItems];
    for (let i = 0; i < items.length; i++) {
      //console.log(i, index);
      if (i === index) {
        //console.log("=");
        items[i].quantity = value > 0 ? parseInt(value) : 1;
        setOrderItems(items);
        break;
      }
    }
    //items[0].quantity = parseInt(value);
    //setOrderItems(items);
    //console.log(items);
  };

  const handleProductPriceChange = (index) => (e) => {
    let items = [...orderItems];
    items[index].price =
      e.target.rawValue > 0 ? parseFloat(e.target.rawValue) : 0;
    setOrderItems(items);
  };

  const handleDeleteRow = (index) => (e) => {
    e.preventDefault();
    let items = [...orderItems];
    items.splice(index, 1);
    setOrderItems(items);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setOrder({ ...order, [name]: value });
  };

  const handleAddRow = (e) => {
    e.preventDefault();
    //let items = orderItems;

    //items.push(initProduct);
    //orderItems.push(initProduct);

    setOrderItems((orderItems) => orderItems.concat(initProduct));
    //console.log("add", orderItems);
  };

  const addOrder = (order) => {
    //setOrder([...orders, order]);
    //console.log("add order yay", order);

    for (let i = 0; i < orderItems.length; i++) {
      if (orderItems[i].productId > 0) {
        order.orderItems.push(orderItems[i]);
      }
    }

    OrderApi.createOnlineOrder(order).then((res) => {
      //console.log(res);
      if (res && res.success) {
        history.push("/boss/online-orders/edit/" + res.id);
      }else if (res && res.message) {
        alert(res.message);
      } else {
        alert("Something went wrong");
      }
    });
  };
  const updateOrder = (order) => {
    order.orderItems = [];

    for (let i = 0; i < orderItems.length; i++) {
      if (orderItems[i].productId > 0) {
        order.orderItems.push(orderItems[i]);
      }
    }

    OrderApi.editOnlineOrder(order, props.id).then((res) => {
      //console.log(res)
      if (res && res.success) {
        history.push("/boss/online-orders/edit/" + order.id);
      }else if (res && res.message) {
        alert(res.message);
      } else {
        alert("Something went wrong");
      }
    });
  };

  const newOrderClick = (e) => {
    e.preventDefault();
    //console.log('new order clicked');
    history.push("/boss/online-orders/create");
  };

  const deleteOrderClick = (e) => {
    //console.log('delete order');
    //setShow(false);
    //e.preventDefault();
    if (props.id) {
      OrderApi.deleteOrderById(props.id).then((res) => {
        //console.log('deleted')
        if (res && res.success) {
          history.push("/boss/online-orders");
        } else {
          alert("error");
        }
      });
    }
  };

  const backClick = (e) => {
    e.preventDefault();
    history.push("/boss/online-orders");
  };
  //const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);

  const filterConfig = {
    ignoreCase: true,
    ignoreAccesnt: true,
    trim: true,
    matchForm: "any",
  };

  return (
    <div className="mt-3 pt-3 pb-3 pl-3 pr-3 bg-white">
      <h5>📝 Đơn Hàng Online</h5>
      <hr />
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          if (order.id > 0) {
            updateOrder(order);
          } else {
            addOrder(order);
          }
        }}
      >
        <Form.Row>
          <Col xs={6} sm={6} lg={3}>
            <Form.Group controlId="selectWebsite">
              <Form.Label>Website</Form.Label>
              <Form.Control
                as="select"
                name="websiteId"
                onChange={handleChange}
                value={order.websiteId}
                required
              >
                <option>Chọn website</option>
                <option value="lazada">Lazada</option>
                <option value="sendo">Sendo</option>
                <option value="shopee">Shopee</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs={6} sm={6} lg={3}>
            <Form.Group controlId="txtOrderId">
              <Form.Label>Mã đơn hàng</Form.Label>
              <Form.Control
                placeholder="Mã đơn hàng"
                name="orderId"
                onChange={handleChange}
                value={order.orderId}
                required
              />
            </Form.Group>
          </Col>
          <Col xs={6} sm={6} lg={3}>
            <Form.Group controlId="txtOrderDate" required>
              <Form.Label>Ngày tạo</Form.Label>
              <Form.Control
                type="date"
                name="orderDate"
                value={order.orderDate}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={6} sm={6} lg={3}>
            <Form.Group controlId="selectStage">
              <Form.Label>Tình trạng</Form.Label>
              <Form.Control
                as="select"
                name="stage"
                onChange={handleChange}
                value={order.stage}
              >
                <option>Tình trạng đơn hàng</option>
                <option value="new">Mới</option>
                <option value="delivered">Đã giao</option>
                <option value="completed">Đã thanh toán</option>
                <option value="cancelled">Đã hủy</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs={6} sm={6} lg={3} controlId="txtCustomerName">
            <Form.Label>Khách hàng</Form.Label>
            <Form.Control
              placeholder="Tên khách hàng"
              name="customerName"
              value={order.customerName}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group
            as={Col}
            xs={6}
            sm={6}
            lg={3}
            controlId="txtCustomerPhone"
          >
            <Form.Label>Số điện thoại</Form.Label>
            <Form.Control
              type="Mobile"
              placeholder="Số điện thoại"
              value={order.customerPhone}
              name="customerPhone"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            lg={6}
            controlId="txtDeliveryAddress"
          >
            <Form.Label>Địa chỉ</Form.Label>
            <textarea
              className="form-control"
              rows={2}
              name="deliveryAddress"
              value={order.deliveryAddress}
              onChange={handleChange}
            />
          </Form.Group>
        </Form.Row>

        <Button variant="primary" type="submit">
          Lưu
        </Button>
        <Button variant="success" className="ml-3" onClick={newOrderClick}>
          Tạo order mới
        </Button>
        <Button variant="info" className="ml-3" onClick={backClick}>
          Thoát
        </Button>
        {props.id > 0 && (
          <Button variant="danger" className="ml-3" onClick={toggle}>
            Xóa
          </Button>
        )}
      </Form>

      <Table className="mt-3" responsive bordered striped size="sm">
        <thead>
          <tr>
            <th style={{ width: "60%" }}>Sản phẩm</th>
            <th style={{ width: "15%" }}>Qty</th>
            <th style={{ width: "20%" }}>Giá</th>
            <th style={{ width: "5%" }}></th>
          </tr>
        </thead>
        <tbody>
          {orderItems.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <Select
                    name="order-item"
                    size={1}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "hotpink",
                        primary: "black",
                      },
                    })}
                    isSearchable
                    options={productOptions}
                    filterOption={createFilter(filterConfig)}
                    value={item.selectedOption}
                    onChange={(opt) =>
                      handleProductChange(
                        opt.label,
                        opt.value,
                        opt.price,
                        index
                      )
                    }
                  ></Select>
                </td>
                <td className="text-right">
                  <select
                    name="order-item-qty"
                    className="form-control form-control-sm"
                    onChange={(e) =>
                      handleProductQtyChange(e.target.value, index)
                    }
                    value={orderItems[index].quantity}
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                  </select>
                </td>
                <td>
                  <Cleave
                    name="order-item-price"
                    className="input-numereal form-control form-control-sm"
                    options={{
                      numeral: true,
                      numeralThousandsGroupStyle: "thousand",
                    }}
                    onChange={handleProductPriceChange(index)}
                    value={orderItems[index].price}
                  />
                </td>
                <td>
                  {" "}
                  <input
                    type="button"
                    value="X"
                    onClick={handleDeleteRow(index)}
                  ></input>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={2} className="text-right">
              TỔNG CỘNG
            </td>
            <td colSpan={2} className="text-right">
              <strong>
                {parseFloat(
                  orderItems
                    .map((o) => parseInt(o.quantity) * parseFloat(o.price))
                    .reduce((a, c) => a + c)
                ).toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })}
                đ
              </strong>
            </td>
          </tr>
        </tfoot>
      </Table>
      <Button
        id="btnAddRow"
        name="buttonAddRow"
        onClick={handleAddRow}
        className="btn btn-sm btn-warning"
      >
        <span role="img" aria-label="add new row">➕ Thêm dòng/Add Row</span>
      </Button>

      <ConfirmModal
        isShowing={isShowing}
        hide={toggle}
        title="Khoan! 🤷🏼‍♀️"
        body="Bạn có chắc muốn xóa đơn hàng này không?"
        confirmClick={deleteOrderClick}
      />
    </div>
  );
};
export default AddOrderForm;
