import React, { Component } from "react";
import { Table, Button, Spinner } from "react-bootstrap";
import productsAPI from "../../../api/ProductsAPI";
import "./Order.css";
import { createFilter } from "react-select";
import CreatebleSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
import Cleave from "cleave.js/react";
import OrdersAPI from "../../../api/OrdersAPI";
import CustomersAPI from "../../../api/CustomerAPI";
import OptionListAPI from "../../../api/OptionListAPI";
import {
  GetSortOrderByString,
  GetSortOrderByNumber,
} from "../../../helpers/Sorting";
import { FormatDate } from "../../../helpers/date-time";
import CatalogAPI from "../../../api/catalog-api";
import _ from "lodash";
import debounce from "debounce-promise";

export class Order extends Component {
  static displayName = Order.name;

  constructor(props) {
    super(props);

    this.state = {
      title: "",
      loading: false,
      stageList: [],
      //paymentMethodList: [],
      clientList: [],
      unitList: [],
      order: new OrderItem(),
      products: [],
      productOptions: [],
      showPrintButton: false,
      showBuyingPrice: false,
      customerOptions: [],
      customerSelectedOption: null,
      sortColumn: "",
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddRow = this.handleAddRow.bind(this);
    this.handleAddMoreRow = this.handleAddMoreRow.bind(this);
    this.handleDeleteRow = this.handleDeleteRow.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleQtyChange = this.handleQtyChange.bind(this);
    this.handleCreateProduct = this.handleCreateProduct.bind(this);
    this.handleGeneratePDF = this.handleGeneratePDF.bind(this);
    this.handleCreateCustomer = this.handleCreateCustomer.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleCustomerOldDebtChange = this.handleCustomerOldDebtChange.bind(
      this
    );
    this.handleBuyIngPriceChange = this.handleBuyIngPriceChange.bind(this);
    this.handleShowBuyingPrice = this.handleShowBuyingPrice.bind(this);
    this.handleTableSort = this.handleTableSort.bind(this);
    this.handleUnitChange = this.handleUnitChange.bind(this);

    const wait = 500; // milliseconds
    const loadOptions = (inputValue) => this.getAsyncOptions(inputValue);
    this.debouncedLoadOptions = debounce(loadOptions, wait, {
      leading: true,
    });
  }

  componentDidMount() {
    OptionListAPI.getOptionListByKey("item-unit").then((res) => {
      if (res && res.length > 0) {
        this.setState({ unitList: res });
      }
    });

    const oid = this.props.match.params["oid"];

    // This will set state for Edit
    if (oid) {
      this.setState({ loading: true });
      OrdersAPI.getById(oid).then((data) => {
        if (data) {
          //console.log(data);
          const orderDate = new Date(data.orderDate);
          data.orderDate = FormatDate(orderDate);

          for (let i = 0; i < data.orderItems.length; i++) {
            let product = {
              id: data.orderItems[i].productId,
              name: data.orderItems[i].productName,
              price: data.orderItems[i].price,
              buyingPrice: data.orderItems[i].buyingPrice,
              total:
                parseFloat(data.orderItems[i].price) *
                parseFloat(data.orderItems[i].quantity),
              quantity: data.orderItems[i].quantity,
              unit: data.orderItems[i].unit > 0 ? data.orderItems[i].unit : 0,
              selectedOption: {
                value: data.orderItems[i].productId,
                label: data.orderItems[i].productName,
              },
              orderItemId: data.orderItems[i].id,
            };
            this.state.products.push(product);
          }

          this.setState({
            title: "Xem",
            loading: false,
            order: data,
            showPrintButton: true,
          });
        }
      });
    } else {
      let newOrder = new OrderItem();
      newOrder.orderDate = new Date().toISOString().substr(0, 10);
      newOrder.stage = "new";
      newOrder.orderItems = [];

      CustomersAPI.getAll().then((list) => {
        if (list.length > 0) {
          //this.setState({ customers: list });

          let options = [];

          list.forEach((element) => {
            let option = {
              value: element.id,
              label: element.name,
              phone: element.phone,
              address: element.address,
            };

            options.push(option);
          });

          this.setState({ customerOptions: options });
        }
      });

      this.setState({ title: "Tạo", loading: false, order: newOrder });

      //show 5 empty records
      for (let i = 0; i < 10; i++) {
        this.handleAddRow();
      }
    }
  }

  handleBack() {
    this.props.history.push("/boss/fetch-order/");
  }

  handleGeneratePDF(event) {
    event.preventDefault();

    let data = this.state.order;

    data.orderItems.forEach((element) => {
      if (element.unit > 0) {
        element.unitName = this.state.unitList.find(
          (x) => x.id === element.unit
        ).name;
      } else {
        element.unitName = "";
      }
    });

    let html = generateReceiptHtml(data);

    var mywindow = window.open("", "Hóa đơn", "height=600,width=800");
    mywindow.document.write("<html><head><title>Hóa đơn</title>"); //mywindow.document.write('<link rel="stylesheet" href="main.css" type="text/css" />');
    /*optional stylesheet*/ mywindow.document.write("</head><body >");
    mywindow.document.write(html);
    mywindow.document.write("</body></html>");

    mywindow.print();
    mywindow.close();
  }

  // This will handle the submit form event.
  handleSave(event) {
    //console.log('save clicked');
    event.preventDefault();

    this.setState({ showPrintButton: false, loading: true });

    let orderItems = [];

    if (this.state.products.length <= 0) {
      alert("Chưa có sản phẩm nào trong đơn hàng này");
      this.setState({ loading: false });
      return;
    }

    for (let i = 0; i < this.state.products.length; i++) {
      const productItem = this.state.products[i];

      if (productItem.id <= 0 || !productItem.name) {
        this.state.products.splice(i, 1);
        this.setState(this.state.products);
        continue;
      }

      let item = {
        productId: parseInt(this.state.products[i].id),
        price: parseFloat(this.state.products[i].price),
        quantity: parseFloat(this.state.products[i].quantity),
        unit: parseInt(this.state.products[i].unit),
        id: parseInt(this.state.products[i].orderItemId),
        orderId:
          parseInt(this.state.order.id) > 0 ? parseInt(this.state.order.id) : 0,
        productName: this.state.products[i].name,
        buyingPrice: parseFloat(this.state.products[i].buyingPrice),
      };
      orderItems.push(item);
    }

    //api/customerorder
    let order = {
      note: this.state.order.note ? this.state.order.note : "",
      orderDate: this.state.order.orderDate,
      customerOldDebt:
        parseFloat(this.state.customerOldDebt) > 0
          ? parseFloat(this.state.customerOldDebt)
          : 0,
      customerName: this.state.order.customerName
        ? this.state.order.customerName
        : "",
      customerPhone: this.state.order.customerPhone
        ? this.state.order.customerPhone
        : "",
      customerAddress: this.state.order.customerAddress
        ? this.state.order.customerAddress
        : "",
      customerId:
        parseInt(this.state.order.customerId) > 0
          ? parseInt(this.state.order.customerId)
          : 0,
      orderItems: orderItems,
      id: parseInt(this.state.order.id) > 0 ? parseInt(this.state.order.id) : 0,
      orderNo: this.state.order.orderNo,
    };

    //console.log("handle save", order, JSON.stringify(order));

    // PUT request for Edit product.
    if (this.state.order.id > 0) {
      //console.log("handle edit", order);
      OrdersAPI.edit(order, this.state.order.id).then((res) => {
        //console.log(res);
        if (res) {
          if (res.success) {
            //this.props.history.push("/boss/order/edit/" + order.id);
            this.setState(
              { order: order, showPrintButton: true, loading: false },
              () => {
                //console.log(this.state.order.orderItems);
                alert("Đơn hàng đã được sửa thành công");
              }
            );
          }
        } else {
          alert(res.message);
        }
      });
    }
    // POST request for Add product.
    else {
      OrdersAPI.create(order).then((res) => {
        if (res) {
          if (res.success) {
            //this.setState({ order: order, showPrintButton: false });
            this.props.history.push("/boss/order/edit/" + res.orderId);
            alert("Đơn hàng đã được tạo thành công");
          }
        } else {
          alert(res.message);
        }
      });
    }
  }

  handleChange(event) {
    const { name, value } = event.target;

    let orderCopy = JSON.parse(JSON.stringify(this.state.order));
    //make changes to ingredients
    orderCopy[name] = value;
    this.setState({
      order: orderCopy,
    });
  }

  handleDeleteRow(productIndex) {
    //console.log("before", this.state.products);
    this.state.products.splice(productIndex, 1);
    this.setState(this.state.products);
    //console.log("delete", productIndex);
    //console.log("after", this.state.products);
  }

  handleAddRow(event) {
    //event.preventDefault();

    const product = {
      id: 0,
      name: "",
      price: 0,
      buyingPrice: 0,
      total: 0,
      quantity: 1,
      unit: 61, //cái as default for unit
      selectedOption: null,
      orderItemId: 0,
    };
    this.state.products.push(product);
    this.setState(this.state.products);
  }

  handleAddMoreRow(event) {
    for (let i = 0; i < 10; i++) {
      this.handleAddRow();
    }
  }

  handleCreateProduct(name, index) {
    console.log(name, "create product", index);

    if (name) {
      let newProduct = {
        name: name,
        price: 0,
        buyingPrice: 0,
        productcode: "CRTNEW",
        description: "Created by order",
        enabled: false,
      };

      //console.log(newProduct);

      productsAPI.create(newProduct).then((res) => {
        if (res && res.success && res.product_id > 0) {
          let { products } = this.state;
          products[index].name = name;
          products[index].id = res.product_id;
          products[index].price = 0;
          products[index].buyingPrice = 0;
          products[index].total = 0;
          products[index].selectedOption = {
            value: res.product_id,
            label: name,
          };

          this.setState(products);
        }
      });
    }
  }

  handleProductChange(value, id, price, buyingPrice, unit, index) {
    console.log(value, id, price, buyingPrice);
    if (value && id > 0) {
      let products = this.state.products;

      //prevent adding twice same product
      for (let i = 0; i < products.length; i++) {
        if (products[i].id === id) {
          return false;
        }
      }

      products[index].unit = unit > 0 ? unit : 61; //default is cái
      products[index].name = value;
      products[index].id = id;
      products[index].price = price;
      products[index].buyingPrice = buyingPrice;
      products[index].total =
        parseFloat(price) * parseFloat(products[index].quantity);
      products[index].selectedOption = {
        value: id,
        label: value,
      };

      this.setState(products);

      this.handlePriceChange(price, index);
    } else {
      console.log("select product issue");
    }
  }

  handleQtyChange(event, index) {
    let value = event.target.rawValue;

    if (!value) {
      value = 0;
    }

    let products = this.state.products;
    products[index].quantity = parseFloat(value);
    products[index].total =
      parseFloat(value) * parseFloat(products[index].price);
    this.setState(products);

    //console.log(this.state.products);
    // this.forceUpdate();
  }

  handleUnitChange(event, index) {
    const value = event.target.value;

    let products = this.state.products;
    products[index].unit = parseInt(value);
    this.setState(products);
  }

  handleCustomerOldDebtChange(value) {
    let { order } = this.state;
    order.customerOldDebt = value;
    this.setState(order);
  }

  handlePriceChange(value, index) {
    let products = this.state.products;

    if (value) {
      products[index].price = value;
    } else {
      products[index].price = 0;
    }

    products[index].total =
      parseFloat(products[index].price) * parseFloat(products[index].quantity);
    this.setState(products);
  }

  handleBuyIngPriceChange(value, index) {
    let products = this.state.products;
    if (value) {
      products[index].buyingPrice = value;
    } else {
      products[index].buyingPrice = 0;
    }
    this.setState(products);
  }

  handleCustomerChange(value, id, phone, address) {
    let { order } = this.state;
    order.customerName = value;
    order.customerPhone = phone;
    order.customerAddress = address;
    order.customerId = id;

    const selectedOption = {
      value: id,
      label: value,
    };

    this.setState({ order: order, customerSelectedOption: selectedOption });
    //console.log('customer change',value,id)
  }

  handleCreateCustomer(item) {
    //console.log('create customer', item);
    const selectedOption = {
      value: 0,
      label: item,
    };
    let { order } = this.state;
    order.customerName = item;

    this.setState({ customerSelectedOption: selectedOption, order: order });
    //console.log(this.state.order);
  }

  handleShowBuyingPrice(e) {
    this.setState({ showBuyingPrice: e.target.checked });
    //console.log(this.state.showBuyingPrice, e.target.value);
  }

  handleTableSort(column) {
    let sortedList =
      column === "id" || column === "price" || column === "buying_price"
        ? this.state.products.sort(GetSortOrderByNumber(column))
        : this.state.products.sort(GetSortOrderByString(column));

    let orderItems = this.state.order.orderItems;

    let sortedorderItems = orderItems.sort(GetSortOrderByString("productName"));

    let { order } = this.state;
    order.orderItems = sortedorderItems;

    let sortColumn = this.state.sortColumn;

    //console.log(sortColumn);
    if (sortColumn) {
      const orderColumn = sortColumn.split("-")[0];
      const orderBy = sortColumn.split("-")[1];
      //console.log(orderColumn, orderBy);
      if (column === orderColumn) {
        if (orderBy === "asc") {
          sortedList.reverse();
          sortedorderItems.reverse();
          sortColumn = column + "-dsc";
        } else {
          sortColumn = column + "-asc";
        }
      } else {
        sortColumn = column + "-asc";
      }
    } else {
      sortColumn = column + "-asc";
    }

    this.setState({
      sortColumn: sortColumn,
      products: sortedList,
      order: order,
    });
  }

  getAsyncOptions(inputValue) {
    //console.log("in here");
    if (!inputValue || inputValue.length < 3) {
      return [];
    }

    const customerId = this.state.order.customerId;
    return new Promise((resolve, reject) => {
      CatalogAPI.lookupProducts(inputValue, customerId).then((res) => {
        console.log(inputValue, res);
        if (res && res.length > 0) {
          let options = [];
          for (let i = 0; i < res.length; i++) {
            options.push({
              label: res[i].name,
              value: res[i].id,
              price:
                res[i].wholeSalePrice > 0
                  ? res[i].wholeSalePrice
                  : res[i].price,
              buyingPrice: res[i].buyingPrice,
              unit:
                res[i].wholeSalePrice > 0 && res[i].wspUnit
                  ? res[i].wspUnit
                  : res[i].unit,
            });
          }
          //console.log('|'+inputValue+'|', options)
          resolve(options);
        } else {
          resolve([]);
        }
      });
    });
  }

  render() {
    const filterConfig = {
      ignoreCase: true,
      ignoreAccesnt: true,
      trim: true,
      matchForm: "any",
    };

    const content = this.state.loading ? (
      <h4>
        <Spinner animation="border" /> Loading... ⌛️
      </h4>
    ) : (
      <div>
        <form onSubmit={this.handleSave}>
          <div className="form-group row">
            <input type="hidden" name="id" value={this.state.order.id} />
          </div>
          <div className="form-group row">
            <label htmlFor="txtOrderNo" className="col-md-2 col-form-label">
              Số đơn hàng
            </label>
            <div className="col-md-6">
              <input
                type="text"
                readOnly
                id="txtOrderNo"
                name="orderNo"
                value={this.state.order.orderNo}
                onChange={this.handleChange}
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtOrderDate" className="col-md-2 col-form-label">
              Ngày tạo đơn
            </label>
            <div className="col-md-6">
              <input
                type="date"
                id="txtOrderDate"
                name="orderDate"
                value={this.state.order.orderDate}
                onChange={this.handleChange}
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="txtCustomerName"
              className="col-md-2 col-form-label"
            >
              Khách hàng
            </label>
            <div className="col-md-6">
              {this.state.order.id > 0 ? (
                <input
                  type="text"
                  readOnly={true}
                  id="txtCustomerName"
                  name="customerName"
                  value={this.state.order.customerName}
                  onChange={this.handleChange}
                  className="form-control"
                />
              ) : (
                <CreatebleSelect
                  value={this.state.customerSelectedOption}
                  options={this.state.customerOptions}
                  onChange={(opt) =>
                    this.handleCustomerChange(
                      opt.label,
                      opt.value,
                      opt.phone,
                      opt.address
                    )
                  }
                  onCreateOption={(opt) => this.handleCreateCustomer(opt)}
                  filterOption={createFilter(filterConfig)}
                ></CreatebleSelect>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="txtCustomerPhone"
              className="col-md-2 col-form-label"
            >
              Số điện thoại
            </label>
            <div className="col-md-6">
              <input
                type="text"
                id="txtCustomerPhone"
                name="customerPhone"
                value={this.state.order.customerPhone}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="txtCustomerAddress"
              className="col-md-2 col-form-label"
            >
              Địa chỉ giao hàng
            </label>
            <div className="col-md-6">
              <textarea
                id="txtCustomerAddress"
                name="customerAddress"
                value={this.state.order.customerAddress}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtNote" className="col-md-2">
              Ghi chú
            </label>
            <div className="col-md-6">
              <textarea
                rows="3"
                id="txtNote"
                name="note"
                className="form-control"
                value={this.state.order.note}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="txtOldDebt" className="col-md-2 col-form-label">
              Nợ cũ
            </label>
            <div className="col-md-6">
              <Cleave
                name="txtOldDebt"
                className="input-numereal form-control"
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                }}
                onChange={(e) =>
                  this.handleCustomerOldDebtChange(e.target.rawValue)
                }
                value={this.state.order.customerOldDebt}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="checkboxShowBuyingPrice"
              className="col-md-2 col-form-label"
            >
              Hiện giá vốn
            </label>
            <div className="col-md-6">
              {/* <Form.Check type="switch" id="showBuyingPrice" lablel="Hiện giá vốn" /> */}
              <input
                type="checkbox"
                id="checkboxShowBuyingPrice"
                onChange={this.handleShowBuyingPrice}
                defaultChecked={this.state.showBuyingPrice}
              />
            </div>
          </div>
          <div className="form-group">
            <Button type="submit" className="btn btn-primary mr-3">
              <span role="img" aria-label="button">
                💾 Lưu
              </span>
            </Button>
            {this.state.showPrintButton ? (
              <Button
                variant="outline-info"
                className="mr-3"
                onClick={this.handleGeneratePDF}
              >
                <span role="img" aria-label="button">
                  🖨 In hóa đơn
                </span>
              </Button>
            ) : (
              ""
            )}

            <Button variant="outline-dark" onClick={this.handleBack}>
              <span role="img" aria-label="button">
                🤷🏻‍♀️ Thoát
              </span>
            </Button>
          </div>
        </form>
        <Table
          className="order-items"
          responsive="lg"
          striped
          bordered
          hover
          size="sm"
        >
          <thead>
            {this.state.showBuyingPrice ? (
              <tr>
                <th style={{ width: "5%" }}>#</th>
                <th
                  onClick={(e) => this.handleTableSort("name")}
                  style={{ width: "40%" }}
                >
                  Sản phẩm
                </th>
                <th style={{ width: "10%" }}>Đơn vị tính</th>
                <th style={{ width: "8%" }}>Số lượng</th>
                <th style={{ width: "9%" }}>Đơn giá</th>
                <th className="bg-secondary text-white" style={{ width: "9%" }}>
                  Giá vốn
                </th>
                <th style={{ width: "14%" }}>Tổng</th>
                <th style={{ width: "5%" }}>Xóa</th>
              </tr>
            ) : (
              <tr>
                <th style={{ width: "5%" }}>#</th>
                <th
                  onClick={(e) => this.handleTableSort("name")}
                  style={{ width: "45%" }}
                >
                  Sản phẩm
                </th>
                <th style={{ width: "10%" }}>Đơn vị tính</th>
                <th style={{ width: "10%" }}>Số lượng</th>
                <th style={{ width: "10%" }}>Đơn giá</th>
                <th style={{ width: "15%" }}>Tổng</th>
                <th style={{ width: "5%" }}>Xóa</th>
              </tr>
            )}
          </thead>
          <tbody>
            {this.state.products.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <AsyncCreatableSelect
                      value={item.selectedOption}                      
                      loadOptions={(inputValue) =>
                        this.debouncedLoadOptions(inputValue)
                      }
                      onChange={(opt) =>
                        this.handleProductChange(
                          opt.label,
                          opt.value,
                          opt.price,
                          opt.buyingPrice,
                          opt.unit,
                          index
                        )
                      }
                      onCreateOption={(opt) =>
                        this.handleCreateProduct(opt, index)
                      }
                    ></AsyncCreatableSelect>
                  </td>
                  <td>
                    <select
                      className="form-control"
                      data-val="true"
                      name="unit"
                      value={this.state.products[index].unit}
                      onChange={(e) => this.handleUnitChange(e, index)}
                    >
                      {this.state.unitList.map((i) => (
                        <option key={i.id} value={i.id}>
                          {i.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className={"text-right"}>
                    <Cleave
                      name="txtQty"
                      className="input-numereal form-control"
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: "thousand",
                      }}
                      onChange={(e) => this.handleQtyChange(e, index)}
                      value={this.state.products[index].quantity}
                    />
                  </td>
                  <td className={"text-right"}>
                    {/* <input className={'text-right'} type='number' name='txtPrice' onChange={e => this.handlePriceChange(e.target.value, index)} value={this.state.products[index].price}></input> */}
                    <Cleave
                      name="txtPrice"
                      className="input-numereal form-control"
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: "thousand",
                      }}
                      onChange={(e) =>
                        this.handlePriceChange(e.target.rawValue, index)
                      }
                      value={this.state.products[index].price}
                    />
                  </td>
                  {this.state.showBuyingPrice && (
                    <td className={"text-right bg-secondary text-white"}>
                      <Cleave
                        name="txtBuyingPrice"
                        className="input-numereal form-control"
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: "thousand",
                        }}
                        onChange={(e) =>
                          this.handleBuyIngPriceChange(e.target.rawValue, index)
                        }
                        value={this.state.products[index].buyingPrice}
                      />
                    </td>
                  )}
                  <td className={"text-right"}>
                    {this.state.products[index].total.toLocaleString(
                      navigator.language,
                      {
                        minimumFractionDigits: 0,
                      }
                    )}
                  </td>
                  <td className={"text-center"}>
                    <input
                      type="button"
                      value="X"
                      onClick={(e) => this.handleDeleteRow(index)}
                    ></input>
                  </td>
                </tr>
              );
            })}
          </tbody>

          {this.state.order.customerOldDebt > 0 ? (
            <tfoot>
              <tr>
                <td className={"text-right"} colSpan={5}>
                  <strong>TỔNG CỘNG (1)</strong>
                </td>
                <td
                  className={"text-center"}
                  colSpan={this.state.showPrintButton ? 3 : 2}
                >
                  <strong>
                    {this.state.products.length > 0
                      ? this.state.products
                          .map((o) => o.total)
                          .reduce((a, c) => a + c)
                          .toLocaleString(navigator.language, {
                            minimumFractionDigits: 0,
                          })
                      : "0"}
                    đ
                  </strong>
                </td>
              </tr>
              <tr className="bg-light text-danger">
                <td className={"text-right"} colSpan={5}>
                  <strong>Nợ cũ (2)</strong>
                </td>
                <td
                  className={"text-center"}
                  colSpan={this.state.showPrintButton ? 3 : 2}
                >
                  <strong>
                    {parseFloat(
                      this.state.order.customerOldDebt
                    ).toLocaleString(navigator.language, {
                      minimumFractionDigits: 0,
                    })}
                    đ
                  </strong>
                </td>
              </tr>
              <tr>
                <td className={"text-right"} colSpan={5}>
                  <strong>TỔNG CỘNG (Bao gồm nợ cũ) (1)+(2)</strong>
                </td>
                <td
                  className={"text-center"}
                  colSpan={this.state.showPrintButton ? 3 : 2}
                >
                  <strong>
                    {(
                      parseFloat(
                        this.state.products
                          .map((o) => o.total)
                          .reduce((a, c) => a + c)
                      ) + parseFloat(this.state.order.customerOldDebt)
                    ).toLocaleString(navigator.language, {
                      minimumFractionDigits: 0,
                    })}
                    đ
                  </strong>
                </td>
              </tr>
            </tfoot>
          ) : (
            <tfoot>
              <tr>
                <td className={"text-right"} colSpan={5}>
                  <strong>TỔNG CỘNG</strong>
                </td>
                <td
                  className={"text-center"}
                  colSpan={this.state.showPrintButton ? 3 : 2}
                >
                  <strong>
                    {this.state.products.length > 0
                      ? this.state.products
                          .map((o) => o.total)
                          .reduce((a, c) => a + c)
                          .toLocaleString(navigator.language, {
                            minimumFractionDigits: 0,
                          })
                      : "0"}
                    đ
                  </strong>
                </td>
              </tr>
            </tfoot>
          )}
        </Table>
        <div>
          <Button
            id="btnAddRow"
            name="buttonAddRow"
            onClick={this.handleAddRow}
            className="btn btn-sm btn-warning"
          >
            <span role="img" aria-label="button">
              ➕ Thêm dòng/Add Row
            </span>
          </Button>
          &nbsp; &nbsp;
          <Button
            id="btnAddMoreRow"
            name="buttonAddMoreRow"
            onClick={this.handleAddMoreRow}
            className="btn btn-sm btn-success"
          >
            <span role="img" aria-label="button">
              🤔 Chắc là còn nhiều dòng nữa
            </span>
          </Button>
        </div>
      </div>
    );

    return (
      <div className="mt-3 pt-3 pb-3 pl-3 pr-3 bg-white">
        <h2>
          <span role="img" aria-label="title">
            📝
          </span>{" "}
          {this.state.title} Đơn Hàng
        </h2>
        <hr />
        {content}
      </div>
    );
  }
}

export class OrderItem {
  id = "";
  clientOrderID = "";
  total = 0;
  client = "";
  stage = "";
  customerName = "";
  customerPhone = "";
  customerAddress = "";
  customerId = 0;
  note = "";
  orderDate = "";
  orderNo = "";
  client = "";
  paymentMethod = "";
  shippingService = "";
  customerName = "";
  customerPhone = "";
  customerAddress = "";
  customerOldDebt = 0;
}

function generateReceiptHtml(data) {
  let html = "";

  let styleCss = `<style>
  .invoice-box {
      max-width: 800px;
      margin: auto;
      padding: 30px;
      /* border: 1px solid #eee; */
      /* box-shadow: 0 0 10px rgba(0, 0, 0, .15); */
      font-size: 14px;
      line-height: 24px;
      font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
      color: #555;
  }

  .invoice-box table {
      width: 100%;
      line-height: inherit;
      text-align: left;
      border-collapse: collapse;
      /*border: 1px solid #eee;*/
  }

  .invoice-box table td {
      padding: 5px;
      vertical-align: top;
  }

  .invoice-box table tr td:nth-child(2) {
      text-align: right;
  }

  .invoice-box table tr.top table td {
      padding-bottom: 10px;
  }

  .invoice-box table tr.top table td.title {
      font-size: 45px;
      line-height: 45px;
      color: #333;
  }

  .invoice-box table tr.information table td {
      padding-bottom: 20px;
  }

  .invoice-box table tr.heading td {
      background: #eee;
      border: 1px solid black;
      font-weight: bold;
  }

  .invoice-box table tr.details td {
      padding-bottom: 10px;
      /* border: 1px solid black; */
  }

  .invoice-box table tr.item td {
      /*border-bottom: 1px solid #eee;*/
      border: 1px solid black;
  }

  .invoice-box table tr.item.last td {
      /*border-bottom: none;*/
  }

  .invoice-box table tr.total td:nth-child(2) {
      
  }

  .invoice-box table tr.total td{
    font-weight: bold;
    font-size: 18px;
  }

  .invoice-box table .invoice-information {
    font-size:16px;
  }

  @media only screen and (max-width: 600px) {
      .invoice-box table tr.top table td {
          width: 100%;
          display: block;
          text-align: center;
      }

      .invoice-box table tr.information table td {
          width: 100%;
          display: block;
          text-align: center;
          padding: 10px;
      }
  }

  /** RTL **/
  .rtl {
      direction: rtl;
      font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  }

  .rtl table {
      text-align: right;
  }

  .rtl table tr td:nth-child(2) {
      text-align: left;
  }
</style>`;
  html += styleCss;
  html +=
    '<div class="invoice-box"><table cellpadding="0" cellspacing="0"><tr class="top"><td colspan="6"><table><tr>';

  console.log(data.orderDate);
  let d = new Date(data.orderDate);
  console.log(d);
  let n = d.toLocaleDateString("vi-VN");
  console.log(n);

  html += `<td>Hóa đơn: ${data.orderNo}</td><td style='text-align:right;'>Ngày: ${n}</td></tr></table></td></tr>`;

  let orderItemsHtml = "";
  let grandTotal = 0;

  //console.log("print", data.orderItems);

  for (let i = 0; i < data.orderItems.length; i++) {
    orderItemsHtml += `<tr class="item"><td style='width:5%; text-align:left;'>${
      i + 1
    }</td>
    <td style='width:45%; text-align:left;'>${
      data.orderItems[i].productName ? data.orderItems[i].productName : ""
    }</td>
    <td style='width:8%; text-align:left;'>${
      data.orderItems[i].unitName ? data.orderItems[i].unitName : ""
    }</td>
    <td style='width:10%; text-align:right;'>${data.orderItems[
      i
    ].quantity.toLocaleString(navigator.language, {
      minimumFractionDigits: 0,
    })}</td>
    <td style='width:15%; text-align:right;'>${data.orderItems[
      i
    ].price.toLocaleString(navigator.language, {
      minimumFractionDigits: 0,
    })}đ</td>
    <td style='width:17%; text-align:right;'>${(
      parseFloat(data.orderItems[i].quantity) *
      parseFloat(data.orderItems[i].price)
    ).toLocaleString(navigator.language, {
      minimumFractionDigits: 0,
    })}đ</td></tr>`;

    grandTotal +=
      parseFloat(data.orderItems[i].quantity) *
      parseFloat(data.orderItems[i].price);
  }

  html += `<tr class="information"><td colspan="6"><table class="invoice-information"><tr><td style='width:50%;'><b>Công ty TNHH TM MTV Trần Trung Anh</b><br>19 Trịnh Hoài Đức, P.13, Q.5, TP.HCM<br>083 8567 769  -  091 6899 668<br>tiemvattu.com  -  tiemvattu@gmail.com<br>ACB: 9004229<br>Sacombank: 0602 6531 8609</td><td style='width:50%;'><b>Khách hàng: Anh/Chị ${
    data.customerName
  }</b><br>${data.customerAddress}<br>${data.customerPhone}
                      </td>
                  </tr>
              </table>
          </td>
      </tr>
      <tr class="heading">
      <td style='width:5%; text-align:right;'>
        STT
      </td>
          <td style='width:45%; text-align:center;'>
              Sản phẩm
          </td>
          <td style='width:8%; text-align:center;'>
              Đơn vị tính
          </td>
          <td style='width:10%; text-align:center;'>
            Số lượng
          </td>
          <td style='width:15%; text-align:center;'>
            Đơn giá
          </td>
          <td style='width:17%; text-align:center;'>
            Thành tiền
          </td>
      </tr>
      ${orderItemsHtml}

      <tr class="total">
          <td colspan=3></td>

          <td colspan=3 style='text-align:right; font-size:16px;'>
              Tổng tiền: ${grandTotal.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
              })}
          đ</td>
      </tr>
      ${
        data.customerOldDebt > 0
          ? '<tr class="total"><td colspan=3></td><td colspan=3 style="text-align:right; font-size:16px;">Nợ cũ: ' +
            parseFloat(data.customerOldDebt).toLocaleString(
              navigator.language,
              {
                minimumFractionDigits: 0,
              }
            ) +
            'đ</td></tr><tr class="total"><td colspan=1><td colspan=5 style="text-align:right; font-size:16px;">TỔNG TIỀN (Bao gồm nợ cũ): ' +
            (
              parseFloat(data.customerOldDebt) + parseFloat(grandTotal)
            ).toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            }) +
            "đ</td></tr>"
          : ""
      }
  </table>
</div>`;

  return html;
}
