import Config from "../Config";
import { getWithExpiry } from "../helpers/LocalStorage";

const SuppliersAPI = {
  getAll,
  getById,
  create,
  update,
};

async function getAll() {
  try {
    const url = Config.apiUrl + "Suppliers/";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    return await response.json();
  } catch (err) {
    console.log(err);
  }
}
async function getById(id) {
  try {
    const url = Config.apiUrl + "Suppliers/" + id;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    return await response.json();
  } catch (err) {
    console.log(err);
  }
}
async function create(item) {
  const url = Config.apiUrl + "Suppliers";

  const payload = JSON.stringify(item);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: payload,
  });

  return await response.json();
}
async function update(item) {
  const url = Config.apiUrl + "Suppliers/" + item.id;

  const payload = JSON.stringify(item);

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: payload,
  });

  return await response.json();
}
export default SuppliersAPI;
