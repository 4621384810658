import React, { Component, Suspense } from "react";
import { Switch } from "react-router";

import {PublicRoute} from "./components/PublicRoute";
import { PrivateRoute } from "./components/PrivateRoute";

import { Home } from "./components/Admin/Home/Home";
import { FetchProduct } from "./components/Admin/Products/FetchProduct";
import { Product } from "./components/Admin/Products/Product";
import { FetchOrder } from "./components/Admin/Orders/FetchOrder";
import { Order } from "./components/Admin/Orders/Order";
import Login from "./components/Login/Login";

import "./App.css";
import { Index } from "./components/Client/Index/Index";
import { Contact } from "./components/Client/Contact";
import Details from "./components/Client/Details";
import ProductCatalog from "./components/Client/ProductCatalog";
import Category from "./components/Client/Category/Category";
import { ProductBrand } from "./components/Client/ProductBrand";
import Search from "./components/Client/Search";
import { DIY } from "./components/Client/DIY";
import Cart from "./components/Client/Cart/Cart";
import Checkout from "./components/Client/Checkout/Checkout";
import { Transactions } from "./components/Admin/Transactions/Transactions";
import { Transaction } from "./components/Admin/Transactions/Transaction";
import { Summary } from "./components/Admin/Summary/Summary";
import { CheckPrice } from "./components/Admin/Products/CheckPrice";
import { CreateOnlineOrder } from "./components/Admin/OnlineOrders/CreateOnlineOrder";
import { OnlineOrdersList } from "./components/Admin/OnlineOrders/OnlineOrdersList";
import { PriceList } from "./components/Client/PriceList/PriceList";
import { SuppliersList } from "./components/Admin/Suppliers/SuppliersList";
import { Supplier } from "./components/Admin/Suppliers/Supplier";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Suspense fallback={<h1>Hacking tiemvattu.com .........</h1>}>
        <Switch>
          <PublicRoute exact path="/" component={Index} />
          <PublicRoute path="/index" component={Index} />
          <PublicRoute path="/details/:pid/:name?" component={Details} />
          <PublicRoute path="/contact" component={Contact} />
          <PublicRoute path="/diy" component={DIY} />
          <PublicRoute path="/search/:query" component={Search} />
          <PublicRoute
            path="/catalog/:type?/:records?/:page?"
            component={ProductCatalog}
          />
          <PublicRoute
            path="/product-brand/:brand/:records?/:page?"
            component={ProductBrand}
          />
          <PublicRoute path="/category/:id/:title?" component={Category} />
          <PublicRoute path="/cart" component={Cart} />
          <PublicRoute path="/checkout" component={Checkout} />
          <PublicRoute path="/boss/login" component={Login} />
          <PublicRoute path="/banggia" component={PriceList} />
          <PublicRoute path="/pricelist" component={PriceList} />
          <PrivateRoute
            exact
            path="/boss/"
            component={Home}
            
          />
          <PrivateRoute
            path="/boss/fetch-product"
            component={FetchProduct}
            
          />
          <PrivateRoute
            path="/boss/product/create"
            component={Product}
            
          />
          <PrivateRoute
            path="/boss/product/edit/:pid"
            component={Product}
            
          />
          <PrivateRoute
            path="/boss/fetch-order"
            component={FetchOrder}
            
          />
          <PrivateRoute
            path="/boss/order/create"
            component={Order}
            
          />
          <PrivateRoute
            path="/boss/order/edit/:oid"
            component={Order}
            
          />
          <PrivateRoute
            path="/boss/transactions"
            component={Transactions}
            
          />
          <PrivateRoute
            path="/boss/transaction/create"
            component={Transaction}
            
          />
          <PrivateRoute
            path="/boss/transaction/edit/:id"
            component={Transaction}
            
          />
          <PrivateRoute
            path="/boss/Summary"
            component={Summary}
            
          />
          <PrivateRoute
            path="/boss/CheckPrice"
            component={CheckPrice}
            
          />
          <PrivateRoute
            exact
            path="/boss/online-orders"
            component={OnlineOrdersList}
            
          />
          <PrivateRoute
            exact
            path="/boss/online-orders/create"
            component={CreateOnlineOrder}
            
          />
          <PrivateRoute
            exact
            path="/boss/online-orders/edit/:id"
            component={CreateOnlineOrder}
            
          />
          <PrivateRoute
            exact
            path="/boss/suppliers/edit/:id"
            component={Supplier}
            
          />
          <PrivateRoute
            exact
            path="/boss/suppliers/create"
            component={Supplier}
            
          />
          <PrivateRoute
            exact
            path="/boss/suppliers"
            component={SuppliersList}
            
          />
        </Switch>
      </Suspense>
    );
  }
}
