import Config from "../Config";

const usersAPI = {
  signUp,
  signIn,
  signOut,
};

async function signUp(user) {
  try {
    let response = await fetch(Config.apiUrl + "users/signup", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    let data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
  }
}

async function signIn(user) {
  try {
    let response = await fetch(Config.apiUrl + "users/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify(user),
    });
    //console.log('log in api response', response);
    
    let data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
  }
}

async function signOut() {
  try {
    let response = await fetch(Config.apiUrl + "users/logout", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        //'Authorization' : `Bearer ${Cookies.get('token')}`,
      },
    });

    let data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
  }
}

export default usersAPI;
