import productsAPI from '../api/ProductsAPI';
import ordersAPI from '../api/OrdersAPI';
import * as types from '../containts/ActionTypes';

const receiveProducts = products => ({
    type: types.RECEIVE_PRODUCTS,
    products
  })
  
  export const getAllProducts = () => dispatch => {
    
    productsAPI.getSellingProducts().then(products => {
      //console.log('get all actions products',products);
      dispatch(receiveProducts(products));
    });
  }

  
const addToCartUnsafe = productId => ({
    type: types.ADD_TO_CART,
    productId
  })
  
  export const addToCart = productId => (dispatch, getState) => {
    //if (getState().products.byId[productId].inventory > 0) {
        //console.log('add to cart', productId);
        dispatch(addToCartUnsafe(productId))
    //}
  }

  export const checkout = order => {
   
    return (dispatch,getState) =>{

      dispatch(checkoutStarted());

      const { cart } = getState()

      //console.log('current state cart:', cart);

      //console.log('current state order:', order);

      ordersAPI.create(order).then((data) =>{

        //console.log('action checkout',data);

        if(data.success){

          dispatch(checkoutSuccess(cart));

        }else{

          dispatch(checkoutFailure(data.message));
        }
      });
    
    

    //   ordersAPI.buyProducts(order, (res) => {
    //     if(res.success){
    //   dispatch({
    //     type: types.CHECKOUT_SUCCESS,
    //     cart
    //   })
    // }else{
    //   dispatch({
    //     type: types.CHECKOUT_FAILURE,
    //     cart
    //   })
    // }
 
       //Replace the line above with line below to rollback on failure:
       //dispatch({ type: types.CHECKOUT_FAILURE, cart })
  }
}

  const checkoutStarted = () =>({
    type: types.CHECKOUT_REQUEST
  });

  const checkoutSuccess = order =>({
    type: types.CHECKOUT_SUCCESS,
    payload:{
      ...order
    }
  });

  const checkoutFailure = error =>({
    type: types.CHECKOUT_FAILURE,
    paload:{
      error
    }
  });

  export const removeItem=(productId)=>{
      return{
          type:types.REMOVE_ITEM,
          productId
      }
  }

  export const subtractQuantityUnsafe=(productId)=>{
      return{
          type:types.SUB_QUANTITY,
          productId
      }
}

export const subtractQuantity = productId => (dispatch, getState) => {
      //console.log('subtract quantity', productId);
      dispatch(subtractQuantityUnsafe(productId))
}

const addQuantityUnsafe = productId => ({
        type: types.ADD_QUANTITY,
        productId    
})

export const addQuantity = productId => (dispatch, getState) => {
  //if (getState().products.byId[productId].inventory > 0) {
      //console.log('add quantity', productId);
      dispatch(addQuantityUnsafe(productId))
  //}
}