import React, { Component } from "react";
import { Link } from "react-router-dom";
import { GetTransactions } from "../../../api/TransactionAPI";
import { FormatDate } from "../../../helpers/date-time";

export class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      transactions: [],
    };
  }
  componentDidMount() {
    GetTransactions(null, null).then((data) => {
      if (data) {
        this.setState({ transactions: data });
      }
    });
  }
  handleEdit(id) {
    this.props.history.push("/boss/transaction/edit/" + id);
  }
  render() {
    const content = (
      <div className="mt-3 table-responsive">
        <table className="table table-bordered table-striped table-hover">
          <thead className="thead-light">
            <tr>
              <th className="text-center">ID</th>
              <th className="text-center">Khách hàng</th>
              <th className="text-center">Ngày trả</th>
              <th className="text-center">Số tiền</th>
              <th className="text-center">Edit</th>
            </tr>
          </thead>
          <tbody>
            {this.state.transactions.map((i) => (
              <tr>
                <td className="text-right">{i.id}</td>
                <td>{i.customerName}</td>
                <td className="text-right">
                  {FormatDate(i.transactionTime, "dmy")}
                </td>
                <td className="text-right">
                  {i.amount.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })}
                  đ
                </td>
                <td className="text-center">
                  <button
                    className="btn btn-link"
                    onClick={(id) => this.handleEdit(i.id)}
                  >
                    📝 Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

    return (
      <div className="mt-3 pt-3 pb-3 pl-3 pr-3 bg-white">
        <h2>💰 Danh sách phiếu thu</h2>
        <hr />

        <Link to="/boss/transaction/create" className="btn btn-primary">
          Tạo phiếu thu mới
        </Link>
        <br></br>
        {content}
      </div>
    );
  }
}
