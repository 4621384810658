import React, { Component } from "react";
import {
  Navbar,
  Nav,
  NavDropdown
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import "./NavBar.css";
import Search from "./Search";

//export const history = useHistory();

class NavBar extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = { expanded:false };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  handleToggle(){
    const {expanded} = this.state;
    this.setState({expanded: !expanded});
  }
  handleClick(){
    this.setState({expanded:false});
  }

  render() {
    return (
      <header>
        <div className="nav-bar">
          <Navbar
            collapseOnSelect={true}
            bg="primary"
            variant="dark"
            sticky="top"
            expand="xl"
            fixed="top"
            expanded={this.state.expanded}
            onToggle={this.handleToggle}
          >
            <div className="navbar-button" onClick={this.props.handleOpenSideBarClick}>
              <div className="one"></div>
              <div className="two"></div>
              <div className="three"></div>
            </div>

            <Navbar.Brand style={{ color: "white" }} as={Link} to="/">
              <img
                src="/tta-logo.svg"
                alt="Tiem Vat Tu Logo"
                style={{ width: 30 }}
              />{" "}
              Tiệm Vật Tư
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link as={NavLink} style={{ color: "white" }} to="/" onClick={this.handleClick}>
                  🏠 Trang chủ
                </Nav.Link>
                <Nav.Link as={NavLink} style={{ color: "white" }} to="/contact" onClick={this.handleClick}>
                📞 Liên hệ
                </Nav.Link>
                <Nav.Link as={NavLink} style={{ color: "white" }} to="/pricelist" onClick={this.handleClick}>
                🧾 Bảng giá
                </Nav.Link>
                <Nav.Link as={NavLink} style={{ color: "white" }} to="/cart" onClick={this.handleClick}>
                  🛒 Giỏ hàng
                </Nav.Link>
                {this.props.isAuthenticated ? (
                  <NavDropdown
                    style={{ color: "white" }}
                    title="Hi! Boss"
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item as={NavLink} exact to="/boss/" onClick={this.handleClick}>
                      🏡 Home
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      as={NavLink}
                      exact
                      to="/boss/fetch-product"
                      onClick={this.handleClick}
                    >
                      <i className="fas fa-list-ul"></i> Sản phẩm
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      exact
                      to="/boss/product/create"
                      onClick={this.handleClick}
                    >
                      <i className="fas fa-plus"></i> Tạo sản phẩm
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      exact
                      to="/boss/checkprice"
                      onClick={this.handleClick}
                    >
                      <i className="fas fa-search"></i> Tìm sản phẩm
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={NavLink} exact to="/boss/fetch-order" onClick={this.handleClick}>
                      <i className="fas fa-list-ol"></i> Đơn hàng
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      exact
                      to="/boss/order/create"
                      onClick={this.handleClick}
                    >
                      <i className="fas fa-plus"></i> Tạo đơn hàng
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  ""
                )}
                {this.props.isAuthenticated ? (
                  <Nav.Link
                    style={{ color: "white" }}
                    as={NavLink}
                    to="/boss/login"
                    onClick={this.handleClick}
                  >
                    👋 Logout
                  </Nav.Link>
                ) : (
                  <Nav.Link
                    style={{ color: "white" }}
                    as={NavLink}
                    to="/boss/login"
                    onClick={this.handleClick}
                  >
                    🏃‍♂️ Login
                  </Nav.Link>
                )}
              </Nav>
              <Search />
            </Navbar.Collapse>
          </Navbar>
        </div>
      </header>
    );
  }
}
const mapStateToProps = state => {
  return {
    isAuthenticated:state.authReducer.isAuthenticated   
  };
};

export default connect(mapStateToProps)(NavBar);