import React,{Component} from "react";
import AddOrderForm from "./AddOrderForm";

export class CreateOnlineOrder extends Component {
  render(){
    const id = this.props.match.params["id"];
    return(
      <div>
        <AddOrderForm id={id}/>
      </div>      
    );
  }
}