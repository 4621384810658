import {
    ADD_TO_CART,
    CHECKOUT_REQUEST,
    CHECKOUT_FAILURE,
    CHECKOUT_SUCCESS,
    REMOVE_ITEM,
    SUB_QUANTITY,
    ADD_QUANTITY
  } from '../containts/ActionTypes';

  const initialState = {
    addedIds: [],
    quantityById: {},
    finishCheckout: false,
    error: null
  }
  
  const addedIds = (state = initialState.addedIds, quantity = initialState.quantityById, action) => {
    switch (action.type) {
      case ADD_QUANTITY:
          if (state.indexOf(action.productId) !== -1) {
            return state
          }
          return [ ...state, action.productId ]
        case SUB_QUANTITY:
          if (state.indexOf(action.productId) !== -1) {            
            if(quantity[action.productId] > 1){
              return state
            }else{
              let newAddedIds = state.filter(item => item !== action.productId)
              return newAddedIds

            }
          }
          return [ ...state, action.productId ]
        case REMOVE_ITEM:
              let newAddedIds = state.filter(item => item !== action.productId)
              return newAddedIds
      case ADD_TO_CART:
        if (state.indexOf(action.productId) !== -1) {
          return state
        }
        return [ ...state, action.productId ]
      default:
        return state
    }
  }
  
  const quantityById = (state = initialState.quantityById, action) => {
    const { productId } = action
    switch (action.type) {
      case ADD_QUANTITY:
          
          return { ...state,
            [productId]: (state[productId] || 0) + 1
          }
      case ADD_TO_CART:
        
        return { ...state,
          [productId]: (state[productId] || 0) + 1
        }
      case SUB_QUANTITY:
        
        if(state[productId] > 1){
        return {
          ...state,
          [productId]: (state[productId] || 0) - 1
        }
      } 
      else {
        let newQuantity = delete state[productId]
        return{
          ...state,
          quantityById: newQuantity
        }
      }
      case REMOVE_ITEM:
          let newQuantity = delete state[productId]
          return{
            ...state,
            quantityById: newQuantity
          }
      default:
        return state
    }
  }
  
  export const getQuantity = (state, productId) =>
    state.quantityById[productId] || 0
  
  export const getAddedIds = (state) => {
    //console.log('get added ids',state);
    return state.addedIds
  }
  
  export const checkOutOrder = (state) =>{
    //console.log('cart reducer checkoutOrder', state)
    return state.finishCheckout
  }

  const cart = (state = initialState, action) => {
    //console.log('action', action.type);
    switch (action.type) {
      case CHECKOUT_REQUEST:
        return {
          ...state,
          finishCheckout:false,
          error: null
        }
      case CHECKOUT_FAILURE:
        return {
          ...state,
          finishCheckout:false,
          error: action.payload.error
        }
      case CHECKOUT_SUCCESS:
        return {
          ...initialState,
          finishCheckout:true,
        }
      default:
        return {
          addedIds: addedIds(state.addedIds, state.quantityById, action),
          quantityById: quantityById(state.quantityById, action)
        }
    }
  }
  
  export default cart