import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import './ConfirmModal.css';

const ConfirmModal = ({isShowing, hide, title, body, confirmClick}) => {

    const handleConfirm = (e) =>{
        e.preventDefault();        
        hide();
        confirmClick();
    }

  return (
    <Modal show={isShowing} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            No
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

export default ConfirmModal;