import React, { Component } from "react";

export class Contact extends Component {
  render() {
    return (
      <div className="mt-3 pt-3 pb-3 pl-3 bg-white">
        <div className="container dark-grey-text">
          <p>
            <i className="fas fa-map-marker-alt"></i> Địa chỉ: 19 Trịnh Hoài
            Đức, Phường 13, Quận 5, Hồ Chí Minh{" "}
          </p>
          <p>
            <i className="fas fa-phone"></i> Số điện thoại:{" "}
            <a href="tel:+84-28-3856-7769">028 3856 7769</a>
          </p>
          <p>
            <i className="fas fa-mobile-alt"></i> Di động:{" "}
            <a href="tel:+84-76861-6869">07 6861 6869</a>
          </p>
          <p>
            <i className="far fa-envelope"></i> Email:{" "}
            <a href="mailto:tiemvattu@gmail.com">tiemvattu@gmail.com</a>
          </p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.806202130099!2d106.65647671533401!3d10.749414362629828!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752ef5dff4550b%3A0xafe4d7add1c2a51a!2sTran+Trung+Anh+Company!5e0!3m2!1sen!2s!4v1556772284470!5m2!1sen!2s"
            style={{ width: "100%", height: 500, border: 0 }}
            frameBorder="0"
            allowFullScreen
            title="Shop location"
          ></iframe>
        </div>
      </div>
    );
  }
}
