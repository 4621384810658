import React, { Component } from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import {
  Card,
  Container,
  Col,
  CardDeck,
  CardColumns,
  CardGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./product-card.css";
import { addToCart } from "../../actions/products";
import { getVisibleProducts } from "../../reducers/products";
import ProductAPI from "../../api/ProductsAPI";
import {generateProductNameUrl} from '../../helpers/string-helper';

class ProductCatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      loading: true,
      type: props.type,
      records: props.records,
    };
  }

  componentDidMount() {
    let type = this.state.type;
    let records = this.state.records;

    if (!type) {
      if (this.props.match.params.type) type = this.props.match.params.type;
      else type = "";
    }

    if (!records) {
      if (this.props.match.params.records)
        records = this.props.match.params.records;
    }

    let query = "";

    if (type && type.indexOf("?query=") !== -1) {
      query = type.substring(7, type.length);
    }

    let url = `api/Products/?enabled=true&type=${type}&records=${records}`;

    if (type.indexOf("?query=") !== -1 && query)
      url = `api/Products/?keyword=${query}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ products: data, loading: false });
      });
  }

  // handleClick(id) {
  //     this.props.history.push("/details/" + id);
  // }

  hanldeAddToCart(id) {
    this.props.addToCart(id);
  }

  render() {
    let tempPath = "/img/product/notfound.webp";
    let contents = this.state.loading ? (
      <p>
        <em>Loading...</em>
      </p>
    ) : this.state.products.length > 0 ? (
      this.state.products.map((item) => {
        return (
          <Card key={item.id} body={true} border={"light"}>
            <Link
              to={
                "/details/" + item.id + "/" + generateProductNameUrl(item.name)
              }
              style={{ textDecoration: "none", color: "black" }}
            >
              <Card.Img
                className="card-img-top img-fluid"
                variant="top"
                alt={item.imageAlt}
                loading="lazy"
                src={
                  item.thumbnailPath
                    ? "https://tiemvattu.com/api/Files/GetFile?path=" +
                      item.thumbnailPath
                    : tempPath
                }
                alt={item.name}
              />

              <Card.Body>
                {/* <Card.Title>
                
              </Card.Title> */}

                <div className="card-text">
                  {/* <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to={"/details/" + item.id}
                > */}
                  {item.name}
                  {/* <br/>
                  {item.price.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0
                })}
                đ */}
                  {/* </Link> */}
                </div>
              </Card.Body>
            </Link>
          </Card>
        );
      })
    ) : (
      "No products found"
    );

    return (
      <div className="mt-3 pt-3 pb-3 pl-3 bg-white">
        <div className="container dark-grey-text">
          {/* <h4>Có {this.state.products.length} sản phẩm</h4> */}
          {/* <br></br> */}
          <div className="card-columns">{contents}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: getVisibleProducts(state.products),
});

export default connect(mapStateToProps, { addToCart })(ProductCatalog);
