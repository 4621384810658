import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Spinner, Col, Form, Table } from "react-bootstrap";
import ProductAPI from "../../../api/ProductsAPI";

export class FetchProduct extends Component {
  static displayName = FetchProduct.name;

  constructor(props) {
    super(props);
    this.state = {
      products: [],
      loading: true,
      filtered: [],
      value: "",
      sortColumn: "",
      onlineOnly: true,
    };

    // This binding is necessary to make "this" work in the callback
    //this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTableSort = this.handleTableSort.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });

    ProductAPI.getProductsForAdmin().then((data) => {
      if (data.length > 0) {
        this.setState({ products: data, loading: false });

        if (this.state.onlineOnly) {
          let filtered = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].enabled) {
              filtered.push(data[i]);
            }
          }
          this.setState({ filtered: filtered });
        } else {
          this.setState({ filtered: data });
        }
      }
    });
  }

  handleTableSort(column) {
    let sortedList =
      column === "id" ||
      column === "price" ||
      column === "buying_price" ||
      column === "whole_sale_price"
        ? this.state.filtered.sort(GetSortOrderByNumber(column))
        : this.state.filtered.sort(GetSortOrderByString(column));

    let sortColumn = this.state.sortColumn;

    //console.log(sortColumn);
    if (sortColumn) {
      const orderColumn = sortColumn.split("-")[0];
      const orderBy = sortColumn.split("-")[1];
      //console.log(orderColumn, orderBy);
      if (column === orderColumn) {
        if (orderBy === "asc") {
          sortedList.reverse();
          sortColumn = column + "-dsc";
        } else {
          sortColumn = column + "-asc";
        }
      } else {
        sortColumn = column + "-asc";
      }
    } else {
      sortColumn = column + "-asc";
    }

    this.setState({
      sortColumn: sortColumn,
      filtered: sortedList,
    });
  }

  handleCheckChange(e) {
    const { checked } = e.target;
    const { filtered } = this.state;
    let checkList = [];
    //console.log('check change')
    if (checked) {
      for (let i = 0; i < filtered.length; i++) {
        if (filtered[i].enabled) {
          checkList.push(filtered[i]);
        }
      }
    } else {
      if (this.state.value) {
        let currentList = this.state.products;

        checkList = currentList.filter((item) => {
          const lc = item.name.toLowerCase();

          const filter = this.state.value.toLowerCase();

          return lc.includes(filter);
        });
      } else {
        checkList = this.state.products;
      }
    }
    //console.log(this.state.onlineOnly, this.state.value, this.state.filtered)
    this.setState({ onlineOnly: checked, filtered: checkList });
  }

  handleEdit(id) {
    //console.log(id);

    this.props.history.push("/boss/product/edit/" + id);
  }

  // handleDelete(product) {
  //   var index = this.state.products.indexOf(product);
  //   console.log(product.data);
  //   //this.state.products.splice(index, 1);
  //   //this.setState({ products: this.state.products, loading: false });
  // }

  handleChange(e) {
    // Variable to hold the original version of the list
    let currentList = [];
    // Variable to hold the filtered list before putting into state
    let newList = [];

    if (e.target.value) {
      currentList = this.state.products;

      newList = currentList.filter((item) => {
        if (this.state.onlineOnly && !item.enabled) {
        } else {
          const lc = item.name.toLowerCase();

          const filter = e.target.value.toLowerCase();

          return lc.includes(filter);
        }
      });
    } else {
      if (this.state.onlineOnly) {
        for (let i = 0; i < this.state.products.length; i++) {
          if (this.state.products[i].enabled) {
            newList.push(this.state.products[i]);
          }
        }
      } else {
        newList = this.state.products;
      }
    }
    this.setState({
      filtered: newList,
      value: e.target.value,
    });
  }

  static renderProductsTable(products, props) {
    return (
      <div>
        <Table responsive="sm" hover striped size="sm">
          <thead>
            <tr key="0">
              <th
                className="text-right"
                onClick={(e) => props.handleTableSort("id")}
              >
                ID
              </th>
              <th>Hình</th>
              <th onClick={(e) => props.handleTableSort("name")}>Tên</th>
              <th onClick={(e) => props.handleTableSort("brand")}>
                Thương hiệu
              </th>
              <th
                onClick={(e) => props.handleTableSort("buying_price")}
                className="text-right"
              >
                Giá nhập
              </th>
              <th
                onClick={(e) => props.handleTableSort("whole_sale_price")}
                className="text-right"
              >
                Giá bán sỉ
              </th>
              <th
                onClick={(e) => props.handleTableSort("price")}
                className="text-right"
              >
                Giá bán lẻ
              </th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {products.map((i, index) => (
              <tr className={i.enabled ? "" : "text-danger"} key={index}>
                <td className="text-right">{i.id}</td>
                <td>
                  {" "}
                  {i.thumbnailPath && i.thumbnailPath !== null ? (
                    <img
                      alt="product-thumbnail"
                      width="100px;"
                      style={{ maxHeight: "100px" }}
                      loading="lazy"
                      src={
                        "https://tiemvattu.com/api/Files/GetFile?path=" +
                        i.thumbnailPath
                      }
                    />
                  ) : (
                    ""
                  )}
                </td>

                <td>{i.name}</td>
                <td>{i.brandName}</td>
                <td className="text-right text-danger">
                  {i.buyingPrice.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })}
                  đ
                </td>
                <td className="text-right text-info">
                  {i.wholeSalePrice.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })}
                  đ
                </td>
                <td className="text-right text-success">
                  {i.price.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })}
                  đ
                </td>
                <td>
                  <button
                    className="btn btn-link"
                    onClick={(id) => props.handleEdit(i.id)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    //console.log(this.state.filtered);
    const contents = this.state.loading ? (
      <h4>
        <Spinner animation="border" /> Loading...
      </h4>
    ) : (
      FetchProduct.renderProductsTable(this.state.filtered, this)
    );

    return (
      <div className="mt-3 pt-3 pb-3 pl-3 pr-3 bg-white">
        <h2>Quản lý sản phẩm</h2>
        {/* <Row>
          <Col> */}
        <Link to="/boss/product/create" className="btn btn-primary">
          Tạo sản phẩm mới
        </Link>
        {/* </Col>
        </Row> */}

        <Form>
          <Form.Row className="align-items-center xs-12 m-3">
            <Col xs="7">
              <Form.Label htmlFor="inlineFormInput" srOnly>
                Name
              </Form.Label>
              <Form.Control
                type="{rest.type}"
                id="inlineFormInput"
                placeholder="Tìm theo tên sản phẩm"
                onChange={this.handleChange}
                value={this.state.value}
              />
            </Col>
            <Col xs="5">
              <Form.Check
                type="checkbox"
                label="Chỉ những sản phẩm online"
                name="onlineOnly"
                defaultChecked={this.state.onlineOnly}
                onChange={this.handleCheckChange}
              />
            </Col>
          </Form.Row>
        </Form>
        {/* <Row>
          <Col> */}
        {contents}
        {/* </Col>
          
          </Row> */}
      </div>
    );
  }
}

//Comparer Function
function GetSortOrderByNumber(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

function GetSortOrderByString(prop) {
  return function (a, b) {
    if (a[prop]) {
      return a[prop].localeCompare(b[prop]);
    } else {
      var nameA = a[prop] ? a[prop].toUpperCase() : ""; // bỏ qua hoa thường
      var nameB = b[prop] ? b[prop].toUpperCase() : ""; // bỏ qua hoa thường
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // name trùng nhau
      return 0;
    }
  };
}
