import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";

const items = [
  {
    src: "/img/slide/1.jpg",
    altText: "Đồ nghề cầm tay",
    caption: "Tiệm Vật Tư",
  },
  {
    src: "/img/slide/2.jpg",
    altText: "Thiết bị sửa chữa",
    caption: "Đồ nghề cầm tay chất lượng cao",
  },
  {
    src: "/img/slide/3.jpg", //'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%221140%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
    altText: "Các loại ổ khóa",
    caption: "Từ những thương hiệu uy tín",
  },
];

export class Carousels extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      index: 0,
      direction: null,
    };
  }

  handleSelect(selectedIndex, e) {
    if (selectedIndex && e) {
      this.setState({
        index: selectedIndex,
        direction: e.direction,
      });
    }
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  render() {
    const { index, direction } = this.state;

    const slides = items.map((item) => {
      return (
        <Carousel.Item key={item.altText}>
          <img
            src={item.src}
            alt={item.altText}
            className="d-block w-100"
            width="1140px"
            height="300px"
          />
          <Carousel.Caption>
            <h3>{item.caption}</h3>
            <p>{item.altText}</p>
          </Carousel.Caption>
        </Carousel.Item>
      );
    });

    return (
      <Carousel
        className="d-none d-sm-block d-sm-none d-md-block"
        activeIndex={index}
        direction={direction}
        onSelect={this.handleSelect}
      >
        {slides}
      </Carousel>
    );
  }
}
