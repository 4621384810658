import React, { useState, useEffect } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import SuppliersAPI from "../../../api/SuppliersAPI";
import "../../../css/table.css";

const SuppliersListForm = (props) => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    SuppliersAPI.getAll().then((res) => {
      if (res && res.length) {
        setData(res);
        setSearchResults(res);
      }
    });
  }, []);

  useEffect(() => {
    if (data && data.length) {
      const results = data.filter((item) => {
        const name = item.name.toLowerCase();
        const email = item.email.toLowerCase();
        const address = item.address.toLowerCase();
        const types = item.types.join().toLowerCase();        
        const brands = item.brands.join().toLowerCase();

        const filter = searchTerm.toLowerCase();

        if (
          name.includes(filter) ||
          email.includes(filter) ||
          item.phone.includes(filter) ||
          address.includes(filter) ||
          types.includes(filter) ||
          brands.includes(filter)
        ) {
          return true;
        } else {
          return false;
        }
      });
      setSearchResults(results);
    }
  }, [searchTerm]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="mt-3 pt-3 pb-3 pl-3 pr-3 bg-white">
      <h4>Danh sách nhà cung cấp</h4>
      <Link to="/boss/suppliers/create" className="btn btn-primary">
          Thêm nhà cung cấp
      </Link>
      <div className="pt-3 pb-3">
        <InputGroup size="lg">
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroup-sizing-lg">
              Tìm kiếm
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            aria-label="Large"
            value={searchTerm}
            aria-describedby="inputGroup-sizing-sm"
            onChange={handleChange}
          />
        </InputGroup>
      </div>
      <div>
        <table className="custom-table">
          <thead>
            <tr>
              <th style={{ width: "5%" }} scope="col">
                #
              </th>
              <th style={{ width: "15%" }} scope="col">
                Nhà cung cấp
              </th>
              <th style={{ width: "10%" }} scope="col">
                Điện thoại
              </th>
              <th style={{ width: "25%" }} scope="col">
                Địa chỉ
              </th>
              <th style={{ width: "25%" }} scope="col">
                Loại mặt hàng
              </th>
              <th style={{ width: "15%" }} scope="col">
                Thương hiệu
              </th>
              <th style={{ width: "5%" }} scope="col">
                Edit
              </th>
            </tr>
          </thead>
          <tbody>
            {searchResults.length > 0 ? (
              searchResults.map((item, index) => {
                return (
                  <tr key={index}>
                    <td scope="row" data-label="#" className="text-right">
                      {item.id}
                    </td>
                    <td className="text-left" data-label="Nhà cung cấp">
                      {item.name}
                    </td>
                    <td className="text-left" data-label="Điện thoại">
                      {item.phone ? item.phone.replace(/^\d{3}-\d{6}$/) : <>&nbsp;</>}
                    </td>
                    <td className="text-left" data-label="Địa chỉ">
                      {item.address ? item.address : <>&nbsp;</>}
                    </td>
                    <td className="text-left" data-label="Loại mặt hàng">
                      {item.types.length ? item.types.join() : <>&nbsp;</>}
                    </td>
                    <td className="text-left" data-label="Thương hiệu">
                      {item.brands.length ? item.brands.join() : <>&nbsp;</>}
                    </td>
                    <td>
                      <Link
                        to={"/boss/suppliers/edit/" + item.id}
                        style={{ color: "black" }}
                      >
                        <span role="img" aria-label="">
                          📝 Edit
                        </span>
                      </Link>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="7">Loading...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default SuppliersListForm;
