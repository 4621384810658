import React,{Component} from "react";
import SupplierForm from "./SupplierForm";

export class Supplier extends Component {
  
  render(){   
    const id = this.props.match.params["id"]; 
    return(
      <div>
        <SupplierForm id={id} />
      </div>      
    );
  }
}