import Config from "../Config";
import { getWithExpiry } from "../helpers/LocalStorage";

const SummaryAPI = {
  getTopCustomers,
  getTopProducts,
};

async function getTopCustomers(from, to) {
  //console.log(from, to);
  try {
    let url = Config.apiUrl + "Summary/TopCustomers/";
    if (from && to) {
      url += "?from=" + from + "&to=" + to;
    }

    let response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

async function getTopProducts(from, to) {
  try {
    let url = Config.apiUrl + "Summary/TopProducts/";
    if (from && to) {
      url += "?from=" + from + "&to=" + to;
    }

    let response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export default SummaryAPI;
