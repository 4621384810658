import React, { useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";

const Search = () => {
  const [keyword, setKeyword] = useState("");
  const handleChange = (e) => {    
    setKeyword(e.target.value);
    
    if(e.target.value && e.target.value.length > 4){
      console.log('should log search keyword here, but log after user finish typing',e.target.value);
    }
  };

  return (
    <Form inline>
      <FormControl
        name="query"
        type="text"
        placeholder="Tìm..."
        className="mr-sm-2"
        onChange={handleChange}
        value={keyword}
      />
      <Link to={"/search/" + keyword} >
        <Button type="submit" variant="outline-light">
          <i className="fas fa-search"></i>
        </Button>
      </Link>
    </Form>
  );
};

export default Search;
