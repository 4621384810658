import Config from "../Config";
import { getWithExpiry } from "../helpers/LocalStorage";

const FilesAPI = {
  getFile,
  deleteFileById,
  updateMainPhoto,
};

async function getFile(path) {
  try {
    const url = Config.apiUrl + "Files/GetFile?path=" + path;
    let response = await fetch(url);
    let data = await response.blob();
    return data;
  } catch (err) {
    console.log(err);
  }
}

async function deleteFileById(id) {
  try {
    let response = await fetch(Config.apiUrl + "Files/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    let data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
  }
}

async function updateMainPhoto(payload) {
  try {
    let response = await fetch(Config.apiUrl + "Files/MainPhoto", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    });

    let data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
  }
}

export default FilesAPI;
