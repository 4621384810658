import React, { Component } from "react";
import {
  Form,
  InputGroup,
  FormControl,
  Button,
  Table,
  Spinner,
} from "react-bootstrap";
import ProductsAPI from "../../../api/ProductsAPI";
import { FormatDateTime } from "../../../helpers/date-time";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

export class CheckPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      keyword: "",
      notification: "",
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleChange(e) {
    this.setState({ keyword: e.target.value });
  }

  handleSearch(e) {
    e.preventDefault();
    const { keyword } = this.state;
    this.setState({ loading: true });
    if (!keyword || keyword.length < 3) {
      this.setState({
        loading: false,
        notification: "Vui lòng nhập nhiều hơn 3 kí tự để tìm",
      });
    } else {
      ProductsAPI.getProductPrices(keyword).then((res) => {
        console.log(res);
        if (res && res.length) {
          this.setState({ products: res, notification: "", loading: false });
        } else {
          this.setState({
            notification: "Không tìm thấy sản phẩm nào",
            products: [],
            loading: false,
          });
        }
      });
    }
  }
  renderPriceTable(data) {
    return (
      <Table
        bordered
        striped
        size="sm"
        responsive
        hover
        style={{ fontSize: "small" }}
        variant="dark"
      >
        <thead>
          <tr>
            <th>Price</th>
            <th>Unit</th>
            <th>Date</th>
            <th>Customer</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            return (
              <tr key={item.date}>
                <td className="text-right">
                  {item.price.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })}
                  đ
                </td>
                <td>{item.unit}</td>
                <td>{FormatDateTime(item.date)}</td>
                <td>{item.customer}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
  renderBuyingPriceTable(data) {
    return (
      <div>
        <Table
          bordered
          striped
          size="sm"
          responsive
          hover
          style={{ fontSize: "small" }}
        >
          <thead>
            <tr>
              <th>Price</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => {
              return (
                <tr key={item.date}>
                  <td className="text-right">
                    {item.price.toLocaleString(navigator.language, {
                      minimumFractionDigits: 0,
                    })}
                    đ
                  </td>
                  <td>{FormatDateTime(item.date)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
  renderTable(data) {
    const boldArray = this.state.keyword.split(" ");
    return (
      <Table bordered striped hover className="mt-3 mb-3" responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Hình</th>
            <th>Tên sản phẩm</th>
            <th>Mã sản phẩm</th>
            <th>Giá bán</th>
            <th>Giá vốn</th>
            <th>View/Edit</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const productNameHtml = makeBold(item.name, boldArray);
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  {item.thumbnailPath && item.thumbnailPath !== null ? (
                    <img
                      alt="product-thumbnail"
                      width="100px;"
                      style={{ maxHeight: "100px" }}
                      loading="lazy"
                      src={
                        "https://tiemvattu.com/api/Files/GetFile?path=" +
                        item.thumbnailPath
                      }
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td>{ReactHtmlParser(productNameHtml)}</td>
                <td>{item.code}</td>
                <td>
                  {item.sellingPrices &&
                    item.sellingPrices.length &&
                    this.renderPriceTable(item.sellingPrices)}
                </td>
                <td>
                  {item.buyingPrices &&
                    item.buyingPrices.length &&
                    this.renderBuyingPriceTable(item.buyingPrices)}
                </td>
                <td>
                  <Link
                    key={index}
                    style={{ textDecoration: "none" }}
                    to={"/details/" + item.id}
                    target="_blank"
                  >
                    <i className="fas fa-store"></i>
                    View
                  </Link>
                  <br></br>
                  <Link
                    key={index}
                    style={{ textDecoration: "none" }}
                    to={"/boss/product/edit/" + item.id}
                    target="_blank"
                  >
                    <i className="fas fa-edit"></i>
                    Edit
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  render() {
    const content = this.state.products.length
      ? this.renderTable(this.state.products)
      : "";
    // const alert = this.state.notification
    //   ? setShow(true)
    //   : "";
    return (
      <div className="mt-3 pt-3 pb-3 pl-3 pr-3 bg-white">
        <h2>Tìm sản phẩm</h2>
        {/* {alert} */}
        <Form onSubmit={this.handleSearch}>
          <InputGroup className="mb-3" size="lg">
            <FormControl
              placeholder="Nhập tên hoặc mã sản phẩm"
              aria-label="Nhập tên hoặc mã sản phẩm"
              aria-describedby="basic-addon2"
              value={this.state.keyword}
              onChange={this.handleChange}
              variant="outline-success"
            />
            <InputGroup.Append>
              <Button
                type="submit"
                variant="outline-success"
                size="lg"
                disabled={this.state.loading}
              >
                {this.state.loading ? (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <i className="fas fa-search-dollar"></i>
                )}
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form>
        {content}
        <h5>{this.state.notification}</h5>
      </div>
    );
  }
}
function makeBold(input, wordsToBold) {
  return input.replace(
    new RegExp("(\\b)(" + wordsToBold.join("|") + ")(\\b)", "ig"),
    "$1<b>$2</b>$3"
  );
}
