import React, { Component } from "react";
import { TimeGreeting } from "../../../helpers/date-time";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "./Home.css";

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div className="mt-3 pt-3 pb-3 pl-3 pr-3 bg-white">
        <h1>Chào buổi {TimeGreeting()}</h1>
        <Container>
          <Row>
            <Col>
              <div className="button">
                <Link
                  to={"fetch-order"}
                  style={{ textDecoration: "none", color: "Tomato" }}
                >
                  <span aria-label="emoji" role="img" className="button-text">📋 Đơn hàng</span>
                </Link>
              </div>
            </Col>
            <Col>
              <div className="button">
                <Link
                  to={"order/create"}
                  style={{ textDecoration: "none", color: "Tomato" }}
                >
                  <span aria-label="emoji" role="img" className="button-text">📝 Tạo đơn hàng</span>
                </Link>
              </div>
            </Col>
          </Row>       
          <Row>
            <Col>
              <div className="button">
                <Link
                  to={"online-orders"}
                  style={{ textDecoration: "none", color: "#eec727" }}
                >
                  <span aria-label="emoji" role="img" className="button-text">🎏 Online Orders</span>
                </Link>
              </div>
            </Col>
            <Col>
              <div className="button">
                <Link
                  to={"online-orders/create"}
                  style={{ textDecoration: "none", color: "#eec727" }}
                >
                  <span aria-label="emoji" role="img" className="button-text">💸 Tạo Online Order</span>
                </Link>
              </div>
            </Col>
          </Row>     
          <Row>
            <Col>
              <div className="button">
                <Link
                  to={"fetch-product"}
                  style={{ textDecoration: "none", color: "Dodgerblue" }}
                >
                  <span aria-label="emoji" role="img" className="button-text">🛠 Sản phẩm</span>
                </Link>
              </div>
            </Col>
            <Col>
              <div className="button">
                <Link
                  to={"product/create"}
                  style={{ textDecoration: "none", color: "Dodgerblue" }}
                >
                  <span aria-label="emoji" role="img" className="button-text">🔨 Tạo sản phẩm</span>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="button">
                <Link
                  to={"transactions"}
                  style={{ textDecoration: "none", color: "Mediumslateblue" }}
                >
                  <span aria-label="emoji" role="img" className="button-text">💰 Phiếu thu</span>
                </Link>
              </div>
            </Col>
            <Col>
              <div className="button">
                <Link
                  to={"transaction/create"}
                  style={{ textDecoration: "none", color: "Mediumslateblue" }}
                >
                  <span aria-label="emoji" role="img" className="button-text">💵 Tạo phiếu thu</span>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="button">
                <Link
                  to={"summary"}
                  style={{ textDecoration: "none", color: "MediumSeaGreen" }}
                >
                  <span aria-label="emoji" role="img" className="button-text">📊 Thống kê</span>
                </Link>
              </div>
            </Col>
            <Col>
            <div className="button">
                <Link
                  to={"checkprice"}
                  style={{ textDecoration: "none", color: "MediumSeaGreen" }}
                >
                  <span aria-label="emoji" role="img" className="button-text">🔎 Tìm sản phẩm</span>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="button">
                <Link
                  to={"suppliers"}
                  style={{ textDecoration: "none", color: "MediumSeaGreen" }}
                >
                  <span aria-label="emoji" role="img" className="button-text">🗃 Tìm nhà cung cấp</span>
                </Link>
              </div>
            </Col>
            <Col>
            <div className="button">
                <Link
                  to={"suppliers/create"}
                  style={{ textDecoration: "none", color: "MediumSeaGreen" }}
                >
                  <span aria-label="emoji" role="img" className="button-text">📦 Thêm nhà cung cấp</span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
