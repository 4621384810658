import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import "../helpers/LocalStorage";
// import { getWithExpiry } from '../helpers/LocalStorage';
//import AuthContext from '../contexts/AuthContext';
import { Layout } from './Layout/Layout';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    // <Route {...rest} render={props => (
    //     getWithExpiry('user')
    //         ? <Component {...props} />
    //         : <Redirect to={{ pathname: '/boss/login', state: { from: props.location } }} />
    // )} />
    const isLoggedIn = useSelector(state => {
        //console.log('pricate route', state);
        return state.authReducer.isAuthenticated;
    });

    return isLoggedIn ? (
      <Route
        {...rest}
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    ) : (
      <Redirect to="/boss/login" />
    );
    }