import Config from "../Config";
import { getWithExpiry } from "../helpers/LocalStorage";

const OptionListAPI = {
  getOptionListByKey,
  create,
};

async function getOptionListByKey(key) {
  try {
    const url = Config.apiUrl + "optionlists/" + key;
    let response = await fetch(url);
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}
async function create(item) {
  const url = Config.apiUrl + "OptionLists";

  const payload = JSON.stringify(item);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: payload,
  });

  return await response.json();
}
export default OptionListAPI;
