//Comparer Function
export function GetSortOrderByNumber(prop) {
    return function(a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }
  
  export function GetSortOrderByString(prop) {
    return function(a, b) {
      if (a[prop]) {
        return a[prop].localeCompare(b[prop]);
      } else {
        var nameA = a[prop] ? a[prop].toUpperCase() : ""; // bỏ qua hoa thường
        var nameB = b[prop] ? b[prop].toUpperCase() : ""; // bỏ qua hoa thường
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
  
        // name trùng nhau
        return 0;
      }
    };
  }