import React, { Component } from "react";
import { Nav, Modal, Table, Col } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import "./product-details.css";
import { Link, NavLink } from "react-router-dom";
import { addToCart } from "../../actions/products";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { getVisibleProducts } from "../../reducers/products";
import { isJson } from "../../helpers/Check";
import escapeHtml from "escape-html";
import { Node, Text } from "slate";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

class Details extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      product: new ProductItem(),
      desctiptionHTML: "",
      images: [],
      show: false,
      viewPhoto: "",
    };
    this.handleClose = this.handleClose.bind(this);
    this.onImageClick = this.onImageClick.bind(this);
    this.handleAddToCart = this.handleAddToCart.bind(this);
  }

  componentDidMount() {
    var pid = this.props.match.params["pid"];
    const nameUrl = this.props.match.params["name"];
    console.log(nameUrl);

    if (pid > 0) {
      fetch(`api/Catalog/${pid}`)
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
          if (isJson(data.description)) {
            //console.log(data.description);
            const descJSON = {
              children: JSON.parse(data.description),
            };
            const desctiptionHTML = serialize(descJSON);
            //console.log(desctiptionHTML);
            this.setState({ desctiptionHTML: desctiptionHTML });
          }

          this.setState({ loading: false, product: data });
          let productImages = [];

          if (data.photos.length > 0) {
            for (let i = 0; i < data.photos.length; i++) {
              productImages.push({
                original:
                  "https://tiemvattu.com/api/Files/GetFile?path=" +
                  data.photos[i].original,
                thumbnail:
                  "https://tiemvattu.com/api/Files/GetFile?path=" +
                  data.photos[i].thumbnail,
              });
            }
          } else {
            const imgpath = "/img/product/notfound.webp";
            productImages.push({
              original: imgpath,
              thumbnail: imgpath,
            });
          }
          this.setState({ images: productImages });
        });
    }
  }

  onImageClick(event) {
    const url = event.target.src;
    //this.setState({ show: true, viewPhoto: url });

    // Get the modal
    var modal = document.getElementById("myModal");

    // Get the image and insert it inside the modal - use its "alt" text as a caption
    // var img = document.getElementById("myImg");
    var modalImg = document.getElementById("img01");
    //var captionText = document.getElementById("caption");

    modal.style.display = "block";
    modalImg.src = url;
    //captionText.innerHTML = this.alt;
  }

  handleClose() {
    //this.setState({ show: false, viewPhoto: '' });
    // Get the modal
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
  }

  handleAddToCart(id) {
    this.props.addToCart(id);
  }

  // handleChangeQuantity(e){
  //     if(e.target.value > 0){
  //         this.setState({quantity:e.target.value});
  //     }
  // }

  renderProductInfos(data) {
    return (
      <Table responsive bordered striped size="sm">
        <tbody>
          <tr>
            <th colSpan="2">Thông tin sản phẩm</th>
          </tr>
          {(data.brandName && data.brandName.length > 0) && (
            <tr>
              <td>Thương hiệu</td>
              <td>{data.brandName}</td>
            </tr>
          )}
          {data.productCode && (
            <tr>
              <td>Mã sản phẩm</td>
              <td>{data.productCode}</td>
            </tr>
          )}
          {data.sizeInch > 0 && (
            <tr>
              <td>Kích thước (inch)</td>
              <td>{data.sizeInch}</td>
            </tr>
          )}
          {data.sizeMM > 0 && (
            <tr>
              <td>Kích thước (mm)</td>
              <td>{data.sizeMM}mm</td>
            </tr>
          )}
          {data.weight > 0 && (
            <tr>
              <td>Trọng lượng (gram)</td>
              <td>{data.weight}gr</td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }

  render() {
    const contents = this.state.loading ? (
      <p>loading...</p>
    ) : (
      Details.renderForm(
        this.state,
        this.onImageClick,
        this.handleClose,
        this.handleAddToCart,
        this.renderProductInfos
      )
    );

    return <div>{contents}</div>;

    // return (
    //     <div>
    //         <h1>{this.state.product.name}</h1>
    //         <p><Button variant="outline-primary" onClick={(id) => this.hanldeAddToCart(this.state.product.id)}>Add to cart</Button></p>
    //     </div>
    // )
  }

  static renderForm(
    props,
    imageClick,
    closeModal,
    addToCartClick,
    renderProductInfos
  ) {
    const informationTable = renderProductInfos(props.product);
    return (
      <div className="mt-3 pt-3 pb-3 pl-3 bg-white">
        <div className="container dark-grey-text">
          <Nav defaultActiveKey="/index" as="ul">
            <Nav.Item as="li">
              <Nav.Link as={NavLink} to="/">
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link
                as={NavLink}
                to={"/catalog/" + props.product.typeKey + "/20/1"}
              >
                {props.product.typeName}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link
                as={NavLink}
                to={"/product-brand/" + props.product.brandID + "/20/1"}
              >
                {props.product.brandName}
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <h4>{props.product.name}</h4>
          <div className="row wow fadeIn mt-5 mb-5">
            <div className="col-md-6 mb-4">
              {/*<img src={props.photos[0] ? props.photos[0].path : imgpath} className="img-fluid" alt="" style={{ maxHeight: 400, maxWidth: 500 }} />*/}
              <ImageGallery
                items={props.images}
                onClick={imageClick.bind(this)}
                showNav={false}
                lazyLoad={true}
                showPlayButton={false}
                showFullscreenButton={false}
                thumbnailPosition="left"
              />
            </div>
            <div className="col-md-6 mb-4">
              <div className="p-0">
                <div className="mb-3">
                  <a href="">
                    <span className="badge purple mr-1">
                      {props.product.typeName}
                    </span>
                  </a>
                  <a href="">
                    <span className="badge blue mr-1">
                      {props.product.brandName}
                    </span>
                  </a>
                  <a href="">
                    <span className="badge red mr-1">Bán chạy</span>
                  </a>
                </div>

                <div>{informationTable}</div>

                <p className="lead">
                  <strong>
                    {props.product.price.toLocaleString(navigator.language, {
                      minimumFractionDigits: 0,
                    })}
                    đ
                  </strong>
                </p>
                <form className="d-flex justify-content-left">
                  <Button
                    variant="outline-primary"
                    onClick={(id) => addToCartClick(props.product.id)}
                  >
                    Mua ngay
                    <i className="fas fa-shopping-cart ml-1"></i>
                  </Button>
                </form>
              </div>
            </div>
            <div className="row wow fadeIn">
              <Col>
                <h5>Giới thiệu sản phẩm:</h5>
                <div>{ReactHtmlParser(props.desctiptionHTML)}</div>
              </Col>
            </div>
          </div>
        </div>

        <Modal show={props.show} onHide={closeModal}>
          <Modal.Body>
            <img
              alt="product"
              height={300}
              width={500}
              src={props.viewPhoto}
            ></img>
          </Modal.Body>
        </Modal>

        <div id="myModal" className="modal mt-5">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <img className="modal-content" alt="product" id="img01"></img>
          <div id="caption"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: getVisibleProducts(state.products),
});

export default connect(mapStateToProps, { addToCart })(Details);

export class ProductItem {
  id = 0;
  name = "";
  price = 0;
  brandId = 0;
  typeId = 0;
  description = "";
  productCode = "";
  sku = "";
  weight = 0;
  sizeInch = 0;
  sizeMM = 0;
  height = 0;
  width = 0;
  length = 0;
}
const serialize = (node) => {
  //console.log(node);

  if (Text.isText(node)) {
    return escapeHtml(node.text);
  }

  const children = node.children ? node.children.map((n) => serialize(n)).join("") : '';

  switch (node.type) {
    case "quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "paragraph":
      return `<p>${children}</p>`;
    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case "block-quote":
      return `<blockquote>${children}</blockquote>`;
    case "bulleted-list":
      return `<ul>${children}</ul>`;
    case "heading-one":
      return `<h1>${children}</h1>`;
    case "heading-two":
      return `<h2>${children}</h2>`;
    case "list-item":
      return `<li>${children}</li>`;
    case "numbered-list":
      return `<ol>${children}</ol>`;
    default:
      return children;
  }
};
