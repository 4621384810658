import React, { Component } from "react";
import { Link } from "react-router-dom";
import SummaryAPI from "../../../api/SummaryAPI";
import { Row, Col, Form, Button } from "react-bootstrap";
import {
  FormatDate,
  FirstDateOfMonth,
  LastDateOfMonth,
} from "../../../helpers/date-time";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Tooltip,
} from "recharts";

export class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      topCustomers: [],
      topProducts: [],
      from: FormatDate(FirstDateOfMonth(new Date())),
      to: FormatDate(LastDateOfMonth(new Date())),
    };
    this.onChange = this.onChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }
  componentDidMount() {
    SummaryAPI.getTopCustomers(this.state.from, this.state.to).then((data) => {
      if (data) {
        //console.log("top customers", data);
        this.setState({ topCustomers: data });
      }
    });
    SummaryAPI.getTopProducts(this.state.from, this.state.to).then((data) => {
      if (data) {
        //console.log("top products", data);
        this.setState({ topProducts: data });
      }
    });
  }

  onChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: FormatDate(value) });
    console.log(this.state);
  }

  handleSearch(e) {
    e.preventDefault();

    const { from, to } = this.state;

    if (new Date(from) > new Date(to)) {
      alert("Ngày từ phải nhỏ hơn ngày đến. Vui lòng chọn lại ngày");
      return;
    }

    SummaryAPI.getTopCustomers(this.state.from, this.state.to).then((data) => {
      if (data) {
        //console.log("top customers", data);
        this.setState({ topCustomers: data });
      }
    });
    SummaryAPI.getTopProducts(this.state.from, this.state.to).then((data) => {
      if (data) {
        //console.log("top products", data);
        this.setState({ topProducts: data });
      }
    });
  }

  render() {
    const topCustomersContent = (
      <div className="mt-3 table-responsive">
        <table className="table table-bordered table-striped table-hover">
          <thead className="thead-light">
            <tr>
              <th className="text-center">ID</th>
              <th className="text-center">Khách hàng</th>
              <th className="text-center">Tổng số đơn hàng</th>
              <th className="text-center">Tổng tiền hàng</th>
            </tr>
          </thead>
          <tbody>
            {this.state.topCustomers.map((i, index) => (
              <tr
                key={i.id}
                className={index < 5 ? "text-success font-weight-bold" : ""}
              >
                <td className="text-right">{index + 1}</td>
                <td>
                  {index == 0 ? "👏" : ""}
                  {i.name}
                </td>
                <td className="text-right">
                  {i.count.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })}
                </td>
                <td className="text-right">
                  {i.total.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })}
                  đ
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="text-right" colSpan="3">
                <strong>TỔNG CỘNG</strong>
              </td>
              <td className="text-right">
                <strong>
                  {this.state.topCustomers
                    .reduce((sum, i) => (sum += i.total), 0)
                    .toLocaleString(navigator.language, {
                      minimumFractionDigits: 0,
                    })}
                  đ
                </strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
    const topProductsContent = (
      <div className="mt-3 table-responsive">
        <table className="table table-bordered table-striped table-hover">
          <thead className="thead-light">
            <tr>
              <th className="text-center">ID</th>
              <th className="text-center">Sản phẩm</th>
              <th className="text-center">Tổng lượt mua</th>
              <th className="text-center">Tổng tiền hàng</th>
            </tr>
          </thead>
          <tbody>
            {this.state.topProducts.map((i, index) => (
              <tr
                key={i.id}
                className={index < 5 ? "text-primary font-weight-bold" : ""}
              >
                <td className="text-right">{index + 1}</td>
                <td>
                  <Link
                    to={"/boss/product/edit/" + i.id}
                    style={{ textDecoration: "none" }}
                  >
                    {index == 0 ? "✨" : ""}
                    {i.name}
                  </Link>
                </td>
                <td className="text-right">
                  {i.count.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })}
                </td>
                <td className="text-right">
                  {i.total.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })}
                  đ
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="text-right" colSpan="3">
                <strong>TỔNG CỘNG</strong>
              </td>
              <td className="text-right">
                <strong>
                  {this.state.topProducts
                    .reduce((sum, i) => (sum += i.total), 0)
                    .toLocaleString(navigator.language, {
                      minimumFractionDigits: 0,
                    })}
                  đ
                </strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
    const topCustomersBarChartData = BarChartData(this.state.topCustomers, 5);
    const TopCustomersBarChart = (
      <div className="mt-3 table-responsive">
        <BarChart width={500} height={300} data={topCustomersBarChartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis type="number" unit="triệu" />
          <Tooltip />
          <Legend />
          <Bar dataKey="total" unit=" triệu (vnđ)" fill="#28a745" />
          <Bar dataKey="count" unit=" đơn hàng" fill="#f5bc42" />
        </BarChart>
      </div>
    );

    const topProductsBarChartData = BarChartData(this.state.topProducts, 10);
    const TopCProductsBarChart = (
      <div className="mt-3 table-responsive">
        <BarChart width={500} height={300} data={topProductsBarChartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis type="number" unit="triệu" />
          <Tooltip />
          <Legend />
          <Bar dataKey="total" unit=" triệu (vnđ)" fill="#007bff" />
          <Bar dataKey="count" unit=" đơn hàng" fill="#f5bc42" />
        </BarChart>
      </div>
    );

    return (
      <div className="mt-3 pt-3 pb-3 pl-3 pr-3 bg-white">
        <h2>📊 Thống kê kinh doanh</h2>
        <Row>
          <Col>
            <Form onSubmit={this.handleSearch} inline>
              <Form.Label className="my-1 mr-2" htmlFor="txtFrom">
                Từ ngày
              </Form.Label>
              <Form.Control
                as="input"
                type="date"
                className="my-1 mr-sm-2"
                id="txtFrom"
                name="from"
                value={this.state.from}
                onChange={this.onChange}
                custom
              ></Form.Control>
              <Form.Label className="my-1 mr-2" htmlFor="txtTo">
                Đến ngày
              </Form.Label>
              <Form.Control
                as="input"
                type="date"
                className="my-1 mr-sm-2"
                id="txtTo"
                name="to"
                value={this.state.to}
                onChange={this.onChange}
                custom
              ></Form.Control>
              <Button type="submit" className="my-1">
                Xem
              </Button>
            </Form>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col>
            <h4>TOP Sản Phẩm 🔧🔨🔩🔐</h4>
            {TopCProductsBarChart}
            {topProductsContent}
          </Col>
          <Col>
            <h4>TOP Khách Hàng 👨‍👩‍👦‍👦</h4>
            {TopCustomersBarChart}
            {topCustomersContent}
          </Col>
        </Row>
      </div>
    );
  }
}

function BarChartData(data, limit) {
  let list = [];
  for (let i = 0; i < data.slice(0, limit).length; i++) {
    const item = {
      name: data[i].name,
      count: data[i].count,
      total: parseFloat(data[i].total) / 1000000,
    };
    list.push(item);
  }
  return list;
}
