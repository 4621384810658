import Config from "../Config";
import { getWithExpiry } from "../helpers/LocalStorage";

export async function GetTransactions(from, to) {
  try {
    const url = Config.apiUrl + "Transactions/"; //?from='+from+'&to='+to;

    let response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function GetTransactionById(id) {
  try {
    const url = Config.apiUrl + "Transactions/" + id;

    let response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function CreateTransaction(item) {
  try {
    let response = await fetch(Config.apiUrl + "Transactions/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(item),
    });

    let data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function EditTransaction(item) {
  try {
    let response = await fetch(Config.apiUrl + "Transactions/" + item.id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(item),
    });

    let data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
  }
}
