import Config from "../Config";
import { getWithExpiry } from "../helpers/LocalStorage";

const BrandAPI = {
  getAll,
  create,
};

async function getAll() {
  try {
    const url = Config.apiUrl + "Brands/";
    const response = await fetch(url);
    return await response.json();
  } catch (err) {
    console.log(err);
  }
}
async function create(item) {
  const url = Config.apiUrl + "Brands";

  const payload = JSON.stringify(item);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: payload,
  });

  return await response.json();
}

export default BrandAPI;
