import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DateRangeQuery } from "../../../helpers/date-time";
import Pagination from "../../../helpers/pagination";
import { getWithExpiry } from "../../../helpers/LocalStorage";
import { isToday, isYesterday } from "../../../helpers/date-time";
import { Table } from "react-bootstrap";

export class FetchOrder extends Component {
  static displayName = FetchOrder.name;

  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      loading: true,
      period: "",
      stage: "",
      query: "",
      client: "",
      stageList: [],
      clientList: [],
      currentPage: null,
      totalPages: null,
      allItems: [],
      currentItems: [],
      pageLength: 20,
    };

    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.excuteSearch = this.excuteSearch.bind(this);
  }

  componentDidMount() {
    const token = getWithExpiry("user");
    fetch("api/Orders", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({ orders: data, loading: false });
      });

    fetch("api/optionlists/order-stage")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ stageList: data });
      });

    fetch("api/optionlists/e-commerce-client")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ clientList: data });
      });
  }

  handleFetchError(response) {
    if (!response.ok) throw Error(response.statusText);
    return response;
  }

  handleEdit(id) {
    this.props.history.push("/boss/order/edit/" + id);
  }

  handleChangeSearch(event) {
    const { name, value, type, checked } = event.target;

    switch (name) {
      case "select-stage":
        this.setState({ stage: value }, this.excuteSearch);
        break;
      case "select-period":
        this.setState({ period: value }, this.excuteSearch);
        break;
      case "select-client":
        this.setState({ client: value }, this.excuteSearch);
        break;
      case "text-query":
        if (this._timeout) {
          clearTimeout(this._timeout);
        }

        this._timeout = setTimeout(() => {
          this._timeout = null;
          this.setState({ query: value }, this.excuteSearch);
        }, 500);

        break;
      default:
        break;
    }
    //this.excuteSearch(this.state);
  }

  excuteSearch() {
    var param = "?search=blah";
    var stageParam = "";
    var dateRangeParam = "";
    var clientParam = "";
    var queryParam = "";

    if (this.state.stage) {
      stageParam = "&stage=" + this.state.stage;
    }
    if (this.state.period) {
      dateRangeParam = DateRangeQuery(this.state.period);
    }
    if (this.state.query) {
      queryParam = "&keyword=" + this.state.query;
    }
    if (this.state.client) {
      clientParam = "&client=" + this.state.client;
    }

    param += stageParam + clientParam + dateRangeParam + queryParam;

    const url = "api/Orders/" + param;
    //console.log(url);
    const token = getWithExpiry("user");
    fetch(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        console.log(data);
        if (data.length > 0) {
          this.setState({ orders: data, loading: false });

          const offset = (1 - 1) * this.state.pageLength;
          const currentItems = data.slice(
            offset,
            offset + this.state.pageLength
          );

          this.setState({
            currentPage: 1,
            currentItems,
            totalPages: Math.ceil(data.length / this.state.pageLength),
            allItems: data,
          });
        } else {
          this.setState({
            orders: [],
            loading: false,
            currentItems: [],
            totalPages: 0,
            allItems: [],
          });
        }
      });
  }

  onPageChanged = (data) => {
    const { orders } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentItems = orders.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentItems, totalPages });
  };

  static renderTable(orders, props) {
    return (
      <div>
        <Table responsive>
          <thead className="thead-light">
            <tr>
              <th>ID</th>
              <th>Số đơn hàng</th>
              <th>Khách hàng</th>
              <th>SĐT</th>
              <th className="text-right">Ngày đặt hàng</th>
              <th className="text-right">Tổng tiền</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((i) => (
              <tr
                className={
                  isToday(i.orderDate)
                    ? "bg-white text-primary"
                    : isYesterday(i.orderDate)
                    ? "bg-light text-success"
                    : "bg-default"
                }
                key={i.id}
              >
                <td>{i.id}</td>
                <td>{i.orderNo}</td>
                <td>
                  <strong>
                    {i.customerName
                      ? customerIcon(i.customerId) + i.customerName
                      : "👽 Khách vãng lai"}
                  </strong>
                </td>
                <td>{i.customerPhone}</td>
                <td className="text-right">{i.orderDateFormated}</td>
                <td className="text-right">
                  {i.total.toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  })}
                  đ
                </td>
                <td>
                  <button
                    className="btn btn-link"
                    onClick={(id) => props.handleEdit(i.id)}
                  >
                    📝 Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="4" className="text-right">
                <strong>TỔNG CỘNG</strong>
              </td>
              <td colSpan="2">
                <strong>
                  {" "}
                  {props.state.orders
                    .reduce((sum, i) => (sum += i.total), 0)
                    .toLocaleString(navigator.language, {
                      minimumFractionDigits: 0,
                    })}
                  đ
                </strong>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    );
  }

  static renderPagination(props) {
    const { orders, currentPage, totalPages } = props.state;
    const totalItems = orders.length;

    if (totalItems === 0) return null;

    const headerClass = [
      "text-dark py-2 pr-4 m-0",
      currentPage ? "border-gray border-right" : "",
    ]
      .join(" ")
      .trim();
    return (
      <div className="row col-sm-12">
        <div className="container mb-5">
          <div className="row d-flex flex-row">
            <div className="w-100 px-4 d-flex flex-row flex-wrap align-items-center justify-content-between">
              <div className="d-flex flex-row align-items-center">
                <h2 className={headerClass}>
                  <strong className="text-secondary">{totalItems}</strong> Đơn
                  hàng
                </h2>
                {currentPage && (
                  <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                    Trang{" "}
                    <span className="font-weight-bold">{currentPage}</span> /{" "}
                    <span className="font-weight-bold">{totalPages}</span>
                  </span>
                )}
              </div>
              <div className="d-flex flex-row py-4 align-items-center">
                <Pagination
                  totalRecords={totalItems}
                  pageLimit={props.state.pageLength}
                  pageNeighbours={1}
                  onPageChanged={props.onPageChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <p>
        <em>Loading...</em>
      </p>
    ) : (
      FetchOrder.renderTable(this.state.currentItems, this)
    );

    let pagination = FetchOrder.renderPagination(this);

    return (
      <div className="mt-3 pt-3 pb-3 pl-3 pr-3 bg-white">
        <h2>Danh sách đơn hàng</h2>
        <Link className="btn btn-primary" to="/boss/order/create">
          Tạo đơn hàng mới
        </Link>

        <div className="form-inline mt-3 mb-3">
          <div className="form-group mb-2">
            <label htmlFor="comboPeriod" className="sr-only">
              Thời gian đặt hàng
            </label>
            <select
              className="form-control"
              id="comboPeriod"
              name="select-period"
              value={this.state.period}
              onChange={this.handleChangeSearch}
            >
              <option value="">Chọn thời gian đặt hàng</option>
              <option value="thisweek">Tuần này</option>
              <option value="lastweek">Tuần trước</option>
              <option value="thismonth">Tháng này</option>
              <option value="lastmonth">Tháng trước</option>
            </select>
          </div>
          {/* <div className="form-group mb-2">
            <label htmlFor="comboStage" className="sr-only">
              Tình trạng
            </label>
            <select
              className="form-control"
              data-val="true"
              name="select-stage"
              id="comboStage"
              value={this.state.stage}
              onChange={this.handleChangeSearch}
            >
              <option value="">Chọn tình trạng đơn hàng</option>
              {this.state.stageList.map((i) => (
                <option key={i.id} value={i.value}>
                  {i.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="comboClient" className="sr-only">
              Kênh bán hàng
            </label>
            <select
              className="form-control"
              data-val="true"
              name="select-client"
              id="comboClient"
              value={this.state.client}
              onChange={this.handleChangeSearch}
            >
              <option value="">Tất cả kênh bán hàng</option>
              {this.state.clientList.map((i) => (
                <option key={i.id} value={i.value}>
                  {i.name}
                </option>
              ))}
            </select>
          </div> */}
          <div className="form-group mb-2">
            <label htmlFor="txtSearch" className="sr-only">
              Tìm kiếm
            </label>
            <div className="col-sm-5">
              <input
                type="text"
                className="form-control"
                placeholder="Tìm đơn hàng"
                name="text-query"
                id="txtSearch"
                onChange={this.handleChangeSearch}
              />
            </div>
          </div>
        </div>
        {contents}
        {pagination}
      </div>
    );
  }
}

function customerIcon(customerId) {
  switch (customerId) {
    case 0:
      return "🏃🏼‍♀️";
    case 1:
      return "👨‍👩‍👧‍👧";
    case 2:
      return "🤴🏻";
    case 45:
      return "🕵🏼";
    case 3: //trieu tri ton
      return "⛰";
    case 4: //cao nhan
      return "👨‍🎓";
    case 5: //mai dau tieng
      return "💃";
    case 6: //tuyet quan 7
      return "❄️";
    case 7: //muoi hai
      return "1️⃣2️⃣";
    case 8: //duy
      return "🛵";
    case 11:
      return "🉑";
    case 14:
      return "💁🏻";
    case 18: //Tuan
      return "👞";
    case 19:
      return "❄️";
    case 20: //vinh phuc
      return "🥺";
    case 31: //ong bác
      return "👴🏼";
    case 32:
      return "👧🏻";
    case 34:
      return "👲";
    case 35: //Madagui
      return "🌳";
    case 37:
      return "⭐️";
    case 39: //chu phu yen
      return "🌊";
    case 40: //dung cây lơn
      return "🧥";
    default:
      //khach vang lai
      return "👽";
  }
}
