import React,{Component} from "react";
import SuppliersListForm from "./SuppliersListForm";

export class SuppliersList extends Component {
  render(){    
    return(
      <div>
        <SuppliersListForm />
      </div>      
    );
  }
}