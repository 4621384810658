import React, { Component } from "react";
import { Carousels } from "../Carousel";
import { Link } from "react-router-dom";
import ProductAPI from "../../../api/ProductsAPI";
import { Card } from "react-bootstrap";
import "./Index.css";
import {generateProductNameUrl} from '../../../helpers/string-helper';

export class Index extends Component {
  static displayName = Index.name;

  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }

  componentDidMount() {
    ProductAPI.getProductsHomePage().then((data) => {
      this.setState({ products: data });
    });
  }

  static renderCard(item) {
    //console.log("render card", item);
    return (
      <Card key={item.id} body={true} border={"light"}>
        <Link
          to={'/details/'+item.id+'/'+generateProductNameUrl(item.name)}
          style={{ textDecoration: "none", color: "black" }}
        >
          <Card.Img
            className="card-img-top img-fluid"
            variant="top"
            loading="lazy"
            alt={item.imageAlt}
            src={
              item.thumbnailPath
                ? "https://tiemvattu.com/api/Files/GetFile?path=" +
                  item.thumbnailPath
                : "/img/product/notfound.webp"
            }
            alt={item.name}
          />
          <Card.Body>
            <div className="card-text">{item.name}</div>
          </Card.Body>
        </Link>
      </Card>
    );
  }

  render() {
    const newProducts = (
      <div className="mt-3 pt-3 pb-3 pl-3 bg-white">
        <div className="container dark-grey-text">
          <div className="card-columns">
            {this.state.products
              .filter(function (p) {
                return p.typeKey === "new";
              })
              .map((item) => {
                return Index.renderCard(item);
              })}
          </div>
        </div>
      </div>
    );

    const securityProducts = (
      <div className="mt-3 pt-3 pb-3 pl-3 bg-white">
        <div className="container dark-grey-text">
          <div className="card-columns">
            {this.state.products
              .filter(function (p) {
                return p.typeKey === "security";
              })
              .map((item) => {
                return Index.renderCard(item);
              })}
          </div>
        </div>
      </div>
    );

    const diyProducts = (
      <div className="mt-3 pt-3 pb-3 pl-3 bg-white">
        <div className="container dark-grey-text">
          <div className="card-columns">
            {this.state.products
              .filter(function (p) {
                return p.typeKey === "diy";
              })
              .map((item) => {
                return Index.renderCard(item);
              })}
          </div>
        </div>
      </div>
    );

    const gardenProducts = (
      <div className="mt-3 pt-3 pb-3 pl-3 bg-white">
        <div className="container dark-grey-text">
          <div className="card-columns">
            {this.state.products
              .filter(function (p) {
                return p.typeKey === "garden";
              })
              .map((item) => {
                return Index.renderCard(item);
              })}
          </div>
        </div>
      </div>
    );

    return (
      <div>
        <Carousels />
        <div className="mt-3 mb-3 pt-3 pl-3 bg-white">
          <h4>
            ✨ Sản phẩm mới&nbsp;
            <Link style={{ fontSize: "12px" }} to={""}>
              Xem thêm
            </Link>
          </h4>

          {newProducts}
          {/* <ProductCatalog type="new" records="5" /> */}
        </div>

        <div className="mt-3 mb-3 pt-3 pl-3 bg-white">
          <h4>
            🔐 Thiết bị chống trộm&nbsp;
            <Link style={{ fontSize: "12px" }} to={"/catalog/security/20/1"}>
              Xem thêm
            </Link>
          </h4>
          {securityProducts}
          {/* <ProductCatalog type="security" records="5" /> */}
        </div>

        <div className="mt-3 mb-3 pt-3 pl-3 bg-white">
          <h4>
            🛠 Đồ nghề sữa chữa &nbsp;
            <Link style={{ fontSize: "12px" }} to={""}>
              Xem thêm
            </Link>
          </h4>
          {diyProducts}
          {/* <ProductCatalog type="diy" records="5" /> */}
        </div>

        <div className="mt-3 mb-3 pt-3 pl-3 bg-white">
          <h4>
            🌻 Dụng cụ làm vườn &nbsp;
            <Link style={{ fontSize: "12px" }} to={""}>
              Xem thêm
            </Link>
          </h4>
          {gardenProducts}
          {/* <ProductCatalog type="garden" records="5" /> */}
        </div>
      </div>
    );
  }
}
