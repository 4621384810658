import Config from "../Config";

const CatalogAPI = {
  lookupProducts,
};

async function lookupProducts(keyword, customerId) {
  try {
    const url = `${Config.apiUrl}Catalog/LookUpProducts?customerId=${customerId}&keyword=${keyword}`;
    const response = await fetch(url);
    return await response.json();
  } catch (err) {
    console.log(err);
  }
}

export default CatalogAPI;
