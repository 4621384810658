import usersAPI from '../api/user-api'
import {LOG_IN_REQUEST, LOG_IN_SUCCESS, LOG_IN_FAILURE, LOG_OUT} from '../containts/ActionTypes'
import history from '../helpers/history'

export const login = (username, password, redirect) => {   
    return dispatch =>{
        dispatch(request({username}));
        const user = {
            username:username,
            password:password
        }
        usersAPI.signIn(user).then((res)=>{
            //console.log('action users login', res);
            if(res && res.success){
                dispatch(success(username));
                console.log('action users login success', redirect);
                history.push(redirect);
            }else{
                dispatch(failure('log in failed'))
            }
        })
    }
    function request(user) { return { type: LOG_IN_REQUEST, user } }
    function success(user) { return { type: LOG_IN_SUCCESS, user } }
    function failure(error) { return { type: LOG_IN_FAILURE, error } }
}

export const logout = data =>{
    usersAPI.signOut();
    return {type: LOG_OUT};
}